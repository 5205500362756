import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import thunk from 'redux-thunk'
import logger from 'redux-logger'
import { createStore, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import Routes from './Routes';
import registerServiceWorker from './registerServiceWorker';
import rootReducer from './reducers/RootReducer'

//通信発生時の挙動
const store = createStore(
    rootReducer,
    applyMiddleware(thunk, logger)
)
//store.dispatch(getPosts())

ReactDOM.render(
    <Provider store={store}>
        <Routes />
    </Provider>, 
    document.getElementById('root'));
//registerServiceWorker();

