// 参考
// https://reactjs.org/docs/portals.html
// https://medium.freecodecamp.org/how-to-render-modals-in-react-bbe9685e947e

import React from 'react';
import ReactDOM from 'react-dom';

class Modal extends React.Component {
  constructor(props) {
    super(props);
    // props.childrenにdocument.getElementById使われている場合、先に配置する必要あり
    //this.el = document.createElement('div');
  }

  // それぞれの子コンポーネントにて表示をtrue/falseしているので不要
  componentDidMount() {
    // The portal element is inserted in the DOM tree after
    // the Modal's children are mounted, meaning that children
    // will be mounted on a detached DOM node. If a child
    // component requires to be attached to the DOM tree
    // immediately when mounted, for example to measure a
    // DOM node, or uses 'autoFocus' in a descendant, add
    // state to Modal and only render the children when Modal
    // is inserted in the DOM tree.
    //modalRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    //modalRoot.removeChild(this.el);
  }

  render() {
    return ReactDOM.createPortal(
      // 空のdivを渡しておかないとunmountされる際に削除するchild要素がないためエラーとなる
      (this.props.children == null ? <div style={{display :"none"}}/>: this.props.children),
      //this.el,
      document.getElementById('modal-root'),
      // document.querySelector('#modal-root'),
    );// 違い不明
  }
}


export default Modal;

