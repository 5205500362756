import React from 'react';
import { connect } from 'react-redux';
import { dispatchPostConnections } from '../../actions/PostActions.js';
import { withRouter } from 'react-router-dom';
import * as Const from '../../Const';
import * as Util from '../../Util';
import '../css/MyPage.css';
class NewsDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            current_news_id: props.news_id,
            current_news_info: {},
            prev_news_id: '',
            next_news_id: ''
        };
        document.title = 'ニュース';
        this.handleConnectChange = this.handleConnectChange.bind(this);
        this.props.dispatch(dispatchPostConnections(Const.CONNECT_TYPE_LIST_NEWS, {}, this.handleConnectChange));
    }

    handleConnectChange(type, data, status) {
        if (status !== Const.CONNECT_SUCCESS || !data.success || type !== Const.CONNECT_TYPE_LIST_NEWS) {
            this.props.history.goBack();
        }else {
            let news_info = null;
            let news_id = this.state.current_news_id;
            if (!news_id) {
                for (let i = 0; i < data.data.length; i++) {
                    if (data.data[i].detail_contents) {
                        news_id = data.data[i].news_id;
                        break;
                    }
                }
            }

            let prev_news_id = '';
            let next_news_id = '';
            for (let i = data.data.length-1; i>=0; i--) {
                if (data.data[i].news_id === news_id) {
                    //表示対象のNews
                    news_info = data.data[i];
                    //(日付フォーマットはyyyy/mm/dd)
                    news_info.start_date = Util.displayDate2(news_info.start_date);
                } else if (data.data[i].detail_contents) {
                    //詳細情報のあるNewsのみ対象
                    if (!news_id) news_id = data.data[0].news_id;
                    if (news_info) {
                        prev_news_id = data.data[i].news_id;
                        break;
                    } else {
                        next_news_id = data.data[i].news_id;
                    }
                }
            }
            this.setState({
                current_news_id: news_id,
                current_news_info: news_info,
                prev_news_id: prev_news_id,
                next_news_id: next_news_id
            });
        }
    }

    getNews = (news_id) => {
        this.props.history.replace('/News/'+news_id);
        this.setState({current_news_id: news_id});
        this.props.dispatch(dispatchPostConnections(Const.CONNECT_TYPE_LIST_NEWS, {}, this.handleConnectChange));
    }

    render() {
        let prev = <svg style={styles.arw(this.state.prev_news_id)} xmlns="http://www.w3.org/2000/svg">
            <path id="パス_869" data-name="パス 869" d="M.048,8.546,8.82.139a.574.574,0,0,1,.8,0,.574.574,0,0,1,0,.8L1.291,8.984l8.333,8.041a.574.574,0,0,1,0,.8c-.073.146-.219.146-.365.146a.558.558,0,0,1-.365-.146L.121,9.423a.558.558,0,0,1-.146-.365A1.244,1.244,0,0,1,.048,8.546Z" transform="translate(0.525 0.594)" strokeWidth="1"/>
        </svg>;
        let next = <svg style={styles.arw(this.state.next_news_id)} xmlns="http://www.w3.org/2000/svg">
            <path id="パス_869" data-name="パス 869" d="M9.715,8.546.944.139a.574.574,0,0,0-.8,0,.574.574,0,0,0,0,.8L8.472,8.984.139,17.025a.574.574,0,0,0,0,.8c.073.146.219.146.365.146a.558.558,0,0,0,.365-.146L9.642,9.423a.558.558,0,0,0,.146-.365A1.244,1.244,0,0,0,9.715,8.546Z" transform="translate(0.592 0.525)" strokeWidth="1"/>
        </svg>;

        return <React.Fragment>
            <div className="myPageContentTitle">
                <p className="myPageContentTitleMain">NEWS</p>{document.title}
            </div>
            <div className="myPageContentFrame">
                <p style={{textAlign: 'right', paddingTop: '15px', paddingRight: '15px'}}>{this.state.current_news_info.start_date}</p>
                <p style={{fontSize: '30px', paddingLeft: '15px'}}>{this.state.current_news_info.detail_contents_title}</p>
                <hr />
                <div style={{padding: '15px'}} dangerouslySetInnerHTML={{__html: this.state.current_news_info.detail_contents}}></div>
                {
                    this.state.current_news_info.detail_contents_path &&
                    <a href={this.state.current_news_info.detail_contents_path}>
                        <img src={Const.DOWNLOAD_IMG_URL} alt="download" title="ダウンロード" style={{marginLeft: '20px',marginRight: '10px',width: '30px',transform: 'translateY(25%)'}} />
                        {this.state.current_news_info.detail_contents_path.split('/').pop()}
                    </a>
                }
                <div style={{display: 'flex', position: 'relative', height: '20px', marginTop: '40px'}}>
                    <div className="arw prev" onClick={()=>{if (this.state.prev_news_id) this.getNews(this.state.prev_news_id);}} style={styles.paging(this.state.prev_news_id)}>
                        {prev}{prev}<span>&nbsp;前のニュース</span>
                    </div>
                    <div className="arw next" onClick={()=>{if (this.state.next_news_id) this.getNews(this.state.next_news_id);}} style={styles.paging(this.state.next_news_id)}>
                        <span>次のニュース&nbsp;</span>{next}{next}
                    </div>
                </div>
            </div>
        </React.Fragment>
    }
}

const styles = {
    paging: (available) => {
        return {
            color: (available ? '#333' : '#ddd'),
            cursor: (available ? 'pointer' : 'default'),
            width: '120px',
            display: 'flex'
        }
    },
    arw: (available) => {
        return {
            fill: (available ? '#333' : '#ddd'),
            stroke: (available ? '#333' : '#ddd'),
            width: 10,
            height: 20
        }
    }
}

export default connect()(withRouter(NewsDetail));
