import React, { useState, useEffect } from "react";
import {useDispatch} from 'react-redux';

//通信用のモジュールを読み込み
import { dispatchPostConnections } from '../actions/PostActions';

//定数定義読み込み
import * as Const from '../Const.js';
import * as Util from '../Util.js';
import * as Error from '../ErrorMessages.js';

//各種モジュールを読み込み
import Loading from '../modules/Loading.js';
import Dialog from '../modules/Dialog.js';
import TextBox from '../modules/TextBox.js';
import TextArea from '../modules/TextArea.js';
import SelectBox from '../modules/TestSelectBox';

const CONNECT_TYPE_SENDMAIL = 'message/contact_send';
const OPTION_MASTER_CONTACTFORM = 1;

export default function Contactform(props) {
    const [loading, setLoading] = useState(true);
    const [form, setForm] = useState({option: 0, subject: '', body: ''});
	const [opts, setOpts] = useState([]);
    const [sender, setSender] = useState(['', '', '', '']);
    const [showError, setShowError] = useState(false);
    const [showDialog, setShowDialog] = useState(null);
    const dispatch = useDispatch();

    //初期化
    useEffect(() => {
        document.title = defs.titleJP;
		// 選択項目マスタ取得
		handleConnect(Const.CONNECT_TYPE_MASTER_LIST, {table_name: 'option_master', where: {select_id: OPTION_MASTER_CONTACTFORM}})
        // ユーザー情報取得
        if (localStorage.getItem("user_id")) handleConnect(Const.CONNECT_TYPE_GET_USER_LIST, {});
    }, []);

    //お問い合わせ内容入力のコールバック
    const callbackForm = (id, val) => {
        setForm(prevState => {
            let newForm = {...prevState, [id]: val };
            if (prevState.option !== newForm.option) {
                let selected = opts.filter(v => v.value==val);
                if (selected.length > 0) newForm['subject'] = selected[0].label;
            }
            return newForm;
        });
    }

    //お客様情報入力のコールバック
    const callbackSender = (id, val) => {
        setSender(prevState => { return prevState.map( (v, i) => (i == id ? val : v) ); });
    }

    //送信クリック
    const send = () => {
        let sendForm = {...form};
        let senderChk = true;
        defs.sender.forEach((v, i) => {
            if (v.isRequired && !sender[i].length) senderChk = false;
            sendForm[v.key] = sender[i];
        });
        // バリデーションチェック
        if (!senderChk || !(form.option > 0) || !form.body.length) {
            setShowError(true);
            return;
        }
        // 確認ダイアログ
        let dialogProps = {
            type            : Const.DIALOG_TWO,
            title           : '確認',
            body            : '入力された内容で送信します。よろしいですか？',
            button          : [{
                id                  : "button_left",
                value               : "キャンセル",
                classname           : "buttonshape buttondialog buttongray",
                state               : true,
                callback            : () => { setShowDialog(null); }
            },{
                id                  : "button_right",
                value               : "送信する",
                classname           : "buttonshape buttondialog buttonblue",
                state               : true,
                callback            : () => { handleConnect(CONNECT_TYPE_SENDMAIL, sendForm) }
            }]
        };
        setShowDialog(dialogProps);
    }

    //APIコール
    const handleConnect = (type, data) => {
        setLoading(true);
        setShowDialog(null);
        dispatch(dispatchPostConnections(type, data, handleConnectChange));
    }
    //APIコールバック
    const handleConnectChange = (type, data, status) => {
        setLoading(false);

        // 例外用
        let dialogProps = {
            type            : Const.DIALOG_GENERIC_ERROR,
            title           : 'エラー',
            button          : [{
                id                  : "button_left",
                value               : "戻る",
                classname           : "buttonshape buttondialog buttongray",
                state               : true,
                callback            : () => { setShowDialog(null); }
            }]
        };

        switch (type) {
			case Const.CONNECT_TYPE_MASTER_LIST:
				if (data.success) {
					let opts = data.data.option_master;
					let def = opts.filter(v => v.default_flg=='1');
                    if (def.length > 0) {
                        setForm(prevState => {
                            let newForm = {...prevState};
                            newForm.option = def[0].value;
                            newForm.subject = def[0].label;
                            return newForm;
                        });
                    } else {
                        opts = defs.option.options.concat(opts);
                    }
                    setOpts(opts);
                }
            return;
            case Const.CONNECT_TYPE_GET_USER_LIST:
                if (data.success) {
                    setSender([
                        Util.getUsername(data.data[0]),
                        '',
                        data.data[0].e_mail,
                        data.data[0].tel
                    ]);
                }
            return;
            default:
                if (data.success) {
                    dialogProps.type = Const.DIALOG_ONE;
                    dialogProps.title = 'メール送信完了';
                    dialogProps.body = 'お問い合わせを受付いたしました。';
                    dialogProps.button[0].callback = () => { window.close();props.history.goBack(); };
                }
                break;
        }

        setShowDialog(dialogProps);
    }

    return <React.Fragment>
        <Loading loading_state={loading} />
        {showDialog && <Dialog id={0} {...showDialog} />}
        <div className="myPageContentTitle">
            <p className="myPageContentTitleMain">{defs.title}</p>{defs.titleJP}
        </div>
        <div className="myPageContentFrame">
            <div style={{padding: '15px'}}>
                <div style={styles.descBox}>
                    こちらではドライバへのお見積りのご依頼やお問合せを承っております。
                    <br />
                    下記のフォームに必要事項をご記入いただき、「送信」ボタンを押してください。
                </div>
                <div style={styles.titleSection}>
                    お問い合わせ内容
                </div>
                <div>
                    <table style={styles.tblBox}><tbody>
                        <tr>
                            <ColTitle title={defs.option.title} isRequired={true} />
                            <td style={styles.colContent}>
                                <SelectBox
                                    id='option' className='selectboxFill selectors' 
                                    validation={!showError || form.option > 0} value={form.option} callback={callbackForm} options={opts}
                                />
                            </td>
                        </tr>
                        <tr>
                            <ColTitle title={defs.body.title} isRequired={true} />
                            <ContactformBody value={form.body} showError={showError} callback={callbackForm} />
                        </tr>
                    </tbody></table>
                </div>
                <div style={styles.titleSection}>
                    お客様情報
                </div>
                <div>
                    <table style={styles.tblBox}><tbody>
                        {
                            defs.sender.map( 
                                (v, i) => <tr key={i}><ContactformText {...v} id={i} value={sender[i]} showError={showError} callback={callbackSender} /></tr> 
                            )
                        }
                    </tbody></table>
                </div>
                <div style={styles.descBox}>
                    本フォームよりお問い合わせいただきますと、確認メールをご記入いただいたメールアドレスに送信いたします。
                    <br />
                    暫くしてもメールが届かない場合、メールアドレスが間違って入力された可能性があります。アドレスをご確認の上、再度ご送信ください。
                    <br />
                    <br />
                    迷惑メール防止機能により、迷惑メールフォルダやゴミ箱に自動的に振り分けられる場合があります。
                    <br />
                    「@airshare.co.jp」からのメールが届くよう設定をお願いします。
                    <br />
                    確認メールが届かない場合、メールアドレスが間違っていないか、受信設定に問題がないか等をご確認ください
                </div>
                <div style={{width: 180, margin: 'auto'}}>
                    <div className="buttonshape buttongreen loginButtonFull" onClick={send}>送信</div>
                </div>
            </div>
        </div>
    </React.Fragment>
}

//------------------------------
// 共通タイトル部
//------------------------------
function ColTitle(props) {
    return (
        <td style={styles.colTitle}>
            {props.title}
            {props.isRequired && <span className="required" style={styles.required}>必須</span>}
        </td>
    );
}

//------------------------------
// お問い合わせ内容
//------------------------------
function ContactformBody(props) {
    const [text, setText] = useState({state: Const.TEXT_STATUS_INITIALIZE, value: ''});

    useEffect(() => {
        if (!props.showError) return;
        if (text.state === Const.TEXT_STATUS_INITIALIZE) setText({state: Const.TEXT_STATUS_ERROR, value: text.value});
    }, [props.showError]);

    const callbackText = (type, id, valid, value=null) => {
        let validation = Const.TEXT_STATUS_NORMAL;
        if (type === Const.EVENT_INPUT_BLUR) {
            // フォーカスを外したとき=バリデーション結果を表示
            if (!valid) validation = Const.TEXT_STATUS_ERROR;
            props.callback(id, (valid ? value : ''));
        }
        setText({state: validation, value: (value===null ? text.value : value)});
    };

    return (
        <td style={styles.colContent}>
            <div className="customErrorUser" style={styles.invalid(text.state)}>
                {defs.body.title}
            </div>
            <TextArea 
                id='body'
                length={[1]} validate={[Const.TEXT_VALIDATE_LENGTH_MIN]} 
                state={text.state} value={text.value}
                callback={callbackText}
                type={Const.TEXT_TYPE_TEXT} 
                classname="inputshape formControlTextAreaHeight300px"
            />
        </td>
    );
}

//------------------------------
// お客様情報
//------------------------------
function ContactformText(props) {
    const [text, setText] = useState({state: Const.TEXT_STATUS_INITIALIZE, value: props.value});

    useEffect(() => {
        if (!props.showError) return;
        if (props.isRequired && !text.value) setText({state: Const.TEXT_STATUS_ERROR, value: text.value});
    }, [props.showError]);

    useEffect(() => {
        if (props.value) setText({state: Const.TEXT_STATUS_INITIALIZE, value: props.value});
    }, [props.value]);

    const callbackText = (type, id, valid, value=null) => {
        let validation = Const.TEXT_STATUS_NORMAL;
        if (type === Const.EVENT_INPUT_BLUR) {
            // フォーカスを外したとき=バリデーション結果を表示
            if (!valid) validation = Const.TEXT_STATUS_ERROR;
            props.callback(props.id, (valid ? value : ''));
        }
        setText({state: validation, value: (value===null ? text.value : value)});
    };

    const validate = [Const.TEXT_VALIDATE_LENGTH_MIN, Const.TEXT_VALIDATE_LENGTH_MAX].concat(props.addValid ? [props.addValid] : []);
    const length = [(props.isRequired ? 1 : 0), props.maxLen];

    return <React.Fragment>
        <ColTitle {...props} />
        <td style={styles.colContent}>
            <div className="customErrorUser" style={styles.invalid(text.state)}>
                {props.errMsg}
            </div>
            <TextBox
                id={'ContactformText'+props.id}
                length={length} validate={validate} 
                state={text.state} value={text.value}
                callback={callbackText}
                type={Const.TEXT_TYPE_TEXT} 
                classname="inputshape formControlFull"
            />
            <div style={styles.placeholder}>例）{props.placeholder}</div>
        </td>
    </React.Fragment>;
}

const defs = {
    title: 'CONTACT FORM',
    titleJP: 'お問い合わせフォーム',
    option: {
        title: '何に関するお問い合わせですか？',
        options: [
            {value: 0, label: '選択してください'},
        ]
    },
    body: {
        title: 'お問い合わせ内容をご記入ください',
    },
    sender: [
        {
            key: 'name',
            title: 'お名前',
            isRequired: true,
            maxLen: 100,
            placeholder: 'ドライバ　太郎',
            errMsg: Error.VALIDATE_PASSENGER_NAME
        },
        {
            key: 'name_kana',
            title: 'フリガナ',
            isRequired: false,
            maxLen: 100,
            placeholder: 'ドライバ　タロウ',
            errMsg: Error.VALIDATE_PASSENGER_NAME
        },
        {
            key: 'e_mail',
            title: 'メールアドレス',
            isRequired: true,
            maxLen: 256,
            placeholder: 'airshare_supoort@airshare.co.jp',
            addValid: Const.TEXT_VALIDATE_KIND_MAIL,
            errMsg: Error.VALIDATE_EMAIL
        },
        {
            key: 'tel',
            title: 'お電話番号',
            isRequired: true,
            maxLen: 15,
            placeholder: '11122223333',
            addValid: Const.TEXT_VALIDATE_KIND_NUMBER,
            errMsg: Error.VALIDATE_TEL
        }
    ]
};

const styles = {
    descBox: {
        border: 'solid 1px #808080', 
        padding: 15,
        marginBottom: 25,
        fontSize: 14
    },
    titleSection: {
        fontWeight: 'bold',
        color: '#002060',
        marginBottom: 15,
    },
    tblBox: {
        border: 'solid 1px #808080', 
        width: '100%',
        marginBottom: 25,
    },
    colTitle: {
        border: 'solid 1px #808080', 
        backgroundColor: '#f0f0f0',
        padding: 15,
        width: '40%',
    },
    colContent: {
        border: 'solid 1px #808080', 
        padding: '5px 15px',
    },
    required: {
        float: 'right',
    },
    invalid: (status) => {
        return {
            display: "block", 
            marginLeft: 0, 
            visibility: (status===Const.TEXT_STATUS_ERROR ? "visible" : "hidden"),
        }
    },
    placeholder: {
        fontSize: 14, 
        color: '#333', 
        paddingTop: 5, 
        paddingLeft: 5,
    },
}