// 参考
// https://reactjs.org/docs/portals.html
// https://medium.freecodecamp.org/how-to-render-modals-in-react-bbe9685e947e

import React from 'react';
import { withRouter } from "react-router-dom"; //画面遷移に必要
import './css/Footer.css';
import top_img16 from './images/top_16.svg';
import top_img17 from './images/top_17.svg';
import top_img18 from './images/top_18.svg';
import top_img19 from './images/top_19.svg';

class Footer extends React.Component {
  constructor(props) {
    super(props);
  }

  goPage(page) {
    this.props.history.push({pathname:page, state: {from: this.props.location.pathname}});
  }

  goContact = () => {
    //window.open('/Contactform', 'Contactform');
    this.goPage("/Contactform");
  }

  render() {
    return (
      <div className="footerTop">
        <div className="footerContentBox footerSiteMap">
                  <div className="footerSiteMapContent">
                      <img onClick={(e) => this.goPage("/Top")} src={top_img16} alt="top_16" className=""/>
                  </div>
                  <div className="footerSiteMapBox">
                    <div className="footerSiteMapContent">
                        <div className="" style={styles.aTag} onClick={(e) => this.goPage("/Documents/About")}>DRIVAについて</div>
                        {/*<div className="" style={styles.aTag} onClick={(e) => this.goPage("/Documents/ForOwner")}>航空機オーナーの方へ</div>*/}
                        {/*<div className="" style={styles.aTag} onClick={(e) => this.goPage("/Documents/ForPilot")}>パイロットの方へ</div>*/}
                        {/*<div className="" style={styles.aTag} onClick={(e) => this.goPage("/Documents/safety")}>安全への取り組み</div>*/}
                        {/* <div className=""><a href="/Documents/About">エアシェアについて</a></div>
                        <div className=""><a href="/Documents/ForOwner">航空機オーナーの方へ</a></div>
                        <div className=""><a href="/Documents/ForPilot">パイロットの方へ</a></div> */}
                        {/* <div className="">キャンセルポリシー</div> */}
                    </div>
                    <div className="footerSiteMapContent">
                        <div className="" style={styles.aTag} onClick={(e) => this.goPage("/Documents/terms")}>利用規約</div>
                        <div className="" style={styles.aTag} onClick={(e) => this.goPage("/Documents/terms_pilot")}>ドライバ登録規約</div>
                        <div className="" style={styles.aTag} onClick={(e) => this.goPage("/Documents/terms_owner")}>車両登録規約</div>
                        {/* <div className="">エアシェア用語集</div> */}
                        <div className="" style={styles.aTag} onClick={(e) => this.goPage("/FAQ")}>よくある質問</div>
                        <div className="" style={styles.aTag} onClick={this.goContact}>お問い合わせ</div>
                    </div>
                    <div className="footerSiteMapContent">
                        {/* <div className="">運営会社</div> */}
                        <div className="" style={styles.aTag} onClick={(e) => this.goPage("/Documents/AirshareLawdoc")}>特定商取引法に基づく表示</div>
                        <div className="" style={styles.aTag} onClick={(e) => this.goPage("/Documents/privacy")}>個人情報保護方針</div>
                    </div>
                </div>
        </div>
        <div style={{height:"40px"}}/>
        <div className="footerContentBox">
            <div className="end_middle_text">
                <div>AIR SHARE Inc. (株式会社エアシェア)</div>
                <div>〒080‐2462　北海道帯広市西二十二条北１丁目５番５号</div>
            </div>
            <div className="fotterSNSBox">
                <a className="footerSNS" href="https://www.facebook.com/airshare.jp"><img src={top_img17} alt="facebook" className="footerImg"/></a>
                <a className="footerSNS" href="https://www.instagram.com/airshare.inc/"><img src={top_img18} alt="instagram" className="footerImg"/></a>
                <a className="footerSNS" href="https://twitter.com/airshare_inc"><img src={top_img19} alt="twitter" className="footerImg"/></a>
            </div>
        </div>
      </div>
    )
  }
}

const styles = {
  aTag : {
    cursor : "pointer", 
    textDecoration : "underline"
  }
}

export default withRouter(Footer)
