import React from "react";

import './css/Header.css';

export function NoLoginHeader(){
    return (
      <header className="topLoginContentBox" style={{fontSize:"12px"}}>
          <div className="topHeaderLogo">
              <a className="topHeaderLogoCover" href="/index.html"><img src="/top/DRIVA_top.png" alt="LOGO" className="topHeaderLogoImg" /></a>
          </div>
          <div className="topHeaderRight">
              <div className="topHeaderMenu "><a href="/top.html?for=about">About</a></div>
              {/*<div className="topHeaderMenu "><a href="/top.html?for=about">How to use</a></div>*/}
              {/*<div className="topHeaderMenu "><a href="/top.html?for=owner">For owner</a></div>*/}
              {/*<div className="topHeaderMenu "><a href="/top.html?for=pilot">For pilot</a></div>*/}
              <div className="topHeaderLogin"><a className="topFillLink" href="/Login">Login</a></div>
          </div>
      </header>
    );
}
