import React, { Component } from 'react';

//定数定義読み込み
import * as Const from '../Const.js';

class ValidateTextBox extends Component {
    // バリデートチェック
    // true : OK
    // false : NG
    validate(type, text, num) {
        var validate = "";
        switch (type) {
            //数値
            case Const.TEXT_VALIDATE_KIND_NUMBER:
                validate = /^[0-9]+$/g;
                if (text.match(validate)) {
                    return true;
                }
                break;
            //数値(マイナス有)
            case Const.TEXT_VALIDATE_KIND_SIGNED_NUMBER:
                validate = /^-?[0-9]+$/g;
                if (text.match(validate)) {
                    if (text.length <= num || (text[0] == "-" && text.length <= num + 1))
                    return true;
                }
                break;
            //半角英字
            case Const.TEXT_VALIDATE_KIND_ALPHA:
                validate = /^[a-zA-Z]+$/g;
                if (text.match(validate)) {
                    return true;
                }
                break;
            //半角英数
            case Const.TEXT_VALIDATE_KIND_NUMBER_ALPHA:
                validate = /^[a-zA-Z0-9]+$/g;
                if (text.match(validate)) {
                    return true;
                }
                break;
            //半角英数＋ハイフン
            case Const.TEXT_VALIDATE_KIND_NUMBER_ALPHA_HYPHEN:
                validate = /^[a-zA-Z0-9-]+$/g;
                if (text.match(validate)) {
                    return true;
                }
                break;
            //ASCII
            case Const.TEXT_VALIDATE_KIND_ASCII:
                validate = /^[\x00-\x7F]*$/;
                if (text.match(validate)) {
                    return true;
                }
                break;
            //メールアドレス
            case Const.TEXT_VALIDATE_KIND_MAIL:
                validate = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
                if (text.match(validate)) {
                    return true;
                }
                break;
            //パスワード（半角英数の組み合わせ）
            case Const.TEXT_VALIDATE_KIND_PASSWORD:
                // /^(?=.*?[a-z])(?=.*?\d)[a-z\d]{8,20}$/i
                validate = /^(?=.*?[a-z])(?=.*?\d)[a-z\d]*$/i;
                if (text.match(validate)) {
                    return true;
                }
                break;
            //最低文字数
            case Const.TEXT_VALIDATE_LENGTH_MIN:
                if (text.length >= num) {
                    return true;
                }
                break;
            //最大文字数
            case Const.TEXT_VALIDATE_LENGTH_MAX:
                if (text.length <= num) {
                    return true;
                }
                break;
            case Const.TEXT_VALIDATE_FIRST_POINT:
                if (decimalCheck(text, num, 1)) return true;
                break;
            case Const.TEXT_VALIDATE_SECOND_POINT_HALF:
                if (decimalCheck(text, num, 2)) return true;
                break;
            case Const.TEXT_VALIDATE_FOURTH_POINT:
                if (decimalCheck(text, num, 4)) return true;
                break;
            case Const.TEXT_VALIDATE_EIGHTH_POINT:
                if (decimalCheck(text, num, 8)) return true;
                break;
          //車のナンバー
            case Const.TEXT_VALIDATE_KIND_LICENSE_NUMBER:
                validate = /^[\u30a0-\u30ff\u3040-\u309f\u3005-\u3006\u30e0-\u9fcf]+[a-zA-Z0-9]{1,3}[\u3040-\u309f][0-9]{1,4}$/;
                if (text.match(validate)) {
                    return true;
                }
                break;
            default:
                break;
        }
        return false;
    }

    //画面描画
    render() {
        return (
            <React.Fragment>
            </React.Fragment>
        );
    }
}

//小数点 例：num=6, point=1 の場合はxxxx.y 可変は見送り
function decimalCheck(text, num, point){
    let validate = /^[0-9]+$/g;
    // 小数なしの場合
    if (text.match(validate)) {
        // .yyyyの5文字分除去
        if (text.length <= num - (point + 1)) {
            return true;
        }
    } else {
        let input = text.split(".");
        if (input.length == 2) {
            if (input[0].match(validate) && input[0].length <= num - (point + 1)
                && input[1].match(validate) && 0 < input[1].length && input[1].length <= point) {
                    return true;
            }
        }
    }
}

export default ValidateTextBox;
