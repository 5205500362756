import React, { Component, Suspense, lazy } from "react";
import { BrowserRouter, Route, Switch, useParams } from "react-router-dom";
import Loading  from './modules/Loading';
import {NoLoginPage} from "./components/NoLoginPage";
import {FAQ} from "./components/FAQ";

//CSS読み込み
import './components/css/_base.css';
import './components/css/_ress.css';
import './components/css/Login.css';
import './components/css/UserBase.css';
import './components/css/UserBorrower.css';
import './components/css/UserPilot.css';
import './components/css/UserOwner.css';

const Login = lazy(() => import('./components/Login'));
const RegistrationUser = lazy(() => import('./components/RegistrationUser'));
const EditPilot = lazy(() => import('./components/User/EditPilot'));
const EditOwner = lazy(() => import('./components/User/EditOwner'));
const RegisterNewMember = lazy(() => import('./components/RegisterNewMember'));
const Top = lazy(() => import('./components/Top'));
const ReissuePassword = lazy(() => import('./components/ReissuePassword'));
const CreateFlight = lazy(() => import('./components/CreateFlight'));
const CreateRound = lazy(() => import('./components/CreateRound'));
const MyPage = lazy(() => import('./components/MyPage'));
const TopNews = lazy(() => import('./components/News/TopNews'));
const MatchingDetail = lazy(() => import('./components/MatchingDetail'));

// TODO:Document.jsでcomponentを返す形
// TODO:react-router-domの分岐ドキュメント
export class Routes extends Component {
    render(){
        document.getElementById('payjp-frm').style.display = 'none';
        return (
            <BrowserRouter basename='/'>
                <div>
                <Suspense fallback={<Loading loading_state={true} />}>
                    <Switch>
                    <Route exact path="/"                   component={Top}/>
                    <Route exact path="/index.html"         component={Top}/>
                    <Route exact path="/Top"                component={Top}/>
                    <Route exact path="/Login"              component={Login}/>
                    <Route exact path="/FAQ"                component={FAQ}/>
                    <Route exact path="/RegistrationUser"   component={RegistrationUser}/>
                      {/*<Route exact path="/RegisterNewMember"  component={RegisterNewMember}/>*/}
                    <Route exact path="/RegisterNewMember"  component={Top}/>
                    <Route exact path="/RegisterCOwner"     render={() => <RegisterNewMember user_type='1' />}/>
                    <Route exact path="/RegisterCBorrower"  render={() => <RegisterNewMember user_type='2' />}/>
                    <Route exact path="/RegistrationPilot"  render={() => <EditPilot on_mypage={false} />}/>
                    <Route exact path="/RegistrationOwner"  render={() => <EditOwner on_mypage={false} />}/>
                    <Route exact path="/ReissuePassword"    component={ReissuePassword}/>
                    {/*<Route exact path="/CreateFlight" component={CreateFlight}/>*/}
                    <Route exact path="/CreateRoundNow" render={() => <CreateRound now={true}/>}/>
                    <Route exact path="/CreateRound" render={() => <CreateRound now={false}/>}/>
                    <Route exact path="/TopNews" component={TopNews}/>
                    <Route exact path="/MatchingDetail/Manage/:matching_id" component={(props) => <div id="iframeDiv"><MatchingDetail matching_id={props.match.params.matching_id} manage_auth={true} /></div>}/>
                    <Route component={props => isLogin() ?
                        <MyPage {...props} /> 
                        : 
                        <Switch>
                            <Route exact path="/Documents/About" component={() => window.location.replace("/top.html?for=about")} />
                            <Route exact path="/Documents/ForOwner" component={() => window.location.replace("/top.html?for=owner")} />
                            <Route exact path="/Documents/ForPilot" component={() => window.location.replace("/top.html?for=pilot")} />
                            <Route exact path="/Documents/:forParam" component={() => window.location.replace("/top.html?for="+useParams().forParam)} />
                            <Route component={NoLoginPage}/>
                        </Switch>}/>
                    </Switch>
                </Suspense>
                </div>
            </BrowserRouter>
        )
    }
}

// TODO:redux
function isLogin() {
    var userid = localStorage.getItem("user_id");
    var hashcode = localStorage.getItem("hashcode");
    if ( userid === null || userid === undefined || userid === -1 || userid === "-1" ||
         hashcode === null || hashcode === undefined || hashcode === -1 || hashcode === "-1" ) {
        localStorage.setItem("user_id", "-1");
        return false;
    }
    return true;
}

export default Routes;
