import React, { useState, useRef, useEffect } from "react";

import whiteCloseButtonIcon from '../components/images/whiteCloseButtonIcon.svg';
import contactDialogBackground from './images/contact.jpg';
import chatIcon from '../components/images/message.svg';

import '../components/css/SelectDate.css';
export default function TimerDialog(props) {
    const _FIN_INTERVAL = 180000;
    const [showDialog, toggleDialog] = useState(false);
    const timerId = useRef();
    const interval = useRef(props.nullify ? _FIN_INTERVAL + 1 : 60000);

    //お問い合わせフォームはこちら
    const openWindow = () => {
        window.open('/Contactform', 'Contactform');
    }

    useEffect(() => {
        //タイマーをセット
        const start = () => {
            if (interval.current > _FIN_INTERVAL) return null;
            return setTimeout(() => { toggleDialog(true); }, interval.current);
        }
        //タイマーをリセット
        const restart = () => {
            if (timerId.current) clearTimeout(timerId.current);
            timerId.current = start();
        }
        // タイマーIDを保持
        timerId.current = start();
        // 画面上のクリックを監視
        window.addEventListener('click', restart);
        // クリーンアップ
        return () => window.removeEventListener('click', restart);
    }, []);
    
    useEffect(() => {
        // お問い合わせダイアログを表示後は、タイマーの間隔を変更
        if (showDialog) interval.current = _FIN_INTERVAL + (interval.current < _FIN_INTERVAL ? 0 : 1);
    }, [showDialog]);

    return <React.Fragment>
    {
        showDialog &&
        <div className="overBlackPanel showSelectDepartureTimeDialog" style={styles.frontOfAll}>
            <div style={styles.dialog}>
                <div style={styles.dialogClose}>
                    <img src={whiteCloseButtonIcon} alt="LOGO" style={styles.clickable} onClick={()=> { toggleDialog(false); }}/>
                </div>
                <div style={styles.dialogContent}>
                    <img src={contactDialogBackground} alt="お困りですか？" style={{width: '100%', maxHeight: '100%'}} />
                    <div style={styles.dialogMessage}>
                        <div>問い合わせ・お見積もりなど対応中！</div>
                    </div>
                    <div style={styles.dialogButton}>
                        <div className="buttonshape buttongreen loginButtonFull" onClick={openWindow}>お問い合わせフォームはこちら</div>
                    </div>
                </div>
            </div>
        </div>
    }
        <div style={{opacity: (showDialog ? 0 : 0.8), ...styles.rightBottom, ...styles.frontOfAll}} onClick={() => { toggleDialog(true); }}>
            <img src={chatIcon} alt="お困りですか？" style={styles.clickable} />
        </div>
    </React.Fragment>;
}

const styles = {
    frontOfAll: {
        zIndex: 100000, 
    }, 
    dialog: {
        paddingTop: 100,
        height: 500, 
        width: '90%', 
        maxWidth: 540, 
        zIndex: 'inherit', 
        overflowY: 'auto', 
    }, 
    clickable: {
        cursor: 'pointer',
        width: 30,
        height: 30,
        margin: 0,
    }, 
    dialogClose: {
        textAlign: 'right', 
    }, 
    dialogContent: {
        backgroundColor: '#fff'
    }, 
    dialogMessage: {
        width: '100%', 
        textAlign: 'center', 
        fontWeight: 'bold', 
        fontSize: 24, 
    }, 
    dialogButton: {
        width: 330, 
        margin: 'auto', 
        paddingBottom: 15
    }, 
    rightBottom: {
        transition: 'opacity 1s', 
        position: 'fixed', 
        right: 0, 
        bottom: 0, 
        width: 50, 
        height: 50, 
        margin: 20, 
        padding: 10, 
        backgroundColor: '#6e95cc', 
        borderRadius: '50%', 
    }, 
}