import React from "react";
import {Route, Switch, Redirect, useParams} from 'react-router-dom';
import {SizeMe} from 'react-sizeme';

//定数定義読み込み
import Landing  from './Landing';
import {NoLoginHeader}  from '../modules/Header';
import Footer  from '../modules/Footer';
// import TravelogueList from './Travelogue/TravelogueList.js';
import NewsDetail from "./News/NewsDetail.js";
import Template from "./Template.js";
import Contactform from "./Contactform.js";

export function NoLoginPage(props){
    return (
        <div style={{background:"#fff"}}>
            <div style={{margin: "0 50px", fontSize:"12px"}}>
                <NoLoginHeader />
            </div>
            <div className="noLoginLandingContent">
                <Switch>
                    <Route exact path="/Landing" component={NoLoginLanding}/>
                    <Route exact path="/Contactform" component={() => <Contactform {...props} />}/>
                    <Route path="/News/:news_id?" component={() => <NewsDetail news_id={useParams().news_id} />}/>
                    <Route path="/info/:template_id" component={() => <Template id={useParams().template_id} />}/>
                    <Redirect to={"/Top"}/>
                </Switch>
                <Footer />
            </div>
        </div>
    );
}

function NoLoginLanding(){
    return (
        <React.Fragment>
            <Landing />
            {/*<SizeMe>{({ size }) => <TravelogueList editable={false} divisor={size.width < 1125 ? 4 : 3}/>}</SizeMe>*/}
        </React.Fragment>
    )
}
