import React from 'react';
import { connect } from 'react-redux';
import { dispatchPostConnections } from '../actions/PostActions.js';
import { withRouter } from 'react-router-dom';
import * as Const from '../Const';
class Template extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title_en: '',
            title_jp: '',
            html_content: ''
        };
        this.handleConnectChange = this.handleConnectChange.bind(this);
        this.props.dispatch(
            dispatchPostConnections(
                Const.CONNECT_TYPE_MASTER_LIST, 
                {table_name: 'template_master', where: {url_id: props.id}}, 
                this.handleConnectChange
            )
        );
    }

    handleConnectChange(type, data, status) {
        if (status !== Const.CONNECT_SUCCESS || type !== Const.CONNECT_TYPE_MASTER_LIST) {
            this.props.history.goBack();
            return;
        }
        if (!data.success) {
            this.setState({html_content: 'ページがみつかりません'});
            return;
        }
        document.title = data.data['template_master'][0].title_jp;
        this.setState({
            title_en: data.data['template_master'][0].title_en,
            title_jp: data.data['template_master'][0].title_jp,
            html_content: data.data['template_master'][0].html_content
        });
    }

    render() {
        return <React.Fragment>
            <div className="myPageContentTitle">
                <p className="myPageContentTitleMain">{this.state.title_en}</p>{this.state.title_jp}
            </div>
            <div className="myPageContentFrame">
                <div style={{padding: '15px'}} dangerouslySetInnerHTML={{__html: this.state.html_content}}></div>
            </div>
        </React.Fragment>
    }
}

export default connect()(withRouter(Template));
