/* 共通関数 */

//定数定義読み込み
import * as Const from './Const.js';

/**
 * 対応言語を返す
 * 引数：num
 * 戻り値：Str[]
 */
export function acceptLanguagePack(language){
    let returnList = [];
    // TODO:constにする？
    switch (language){
        case 1:
            returnList.push("日本語");
            break;
        case 2:
            returnList.push("英語");
            break;
        case 3:
            returnList.push("日本語");
            returnList.push("英語");
            break;
    }
    return returnList;
}


/**
 * 分→hh:mm
 * 引数：num
 * 戻り値: Str
 */
export function minutesToTime(time){
    return zeroFill(Math.floor(time / 60), 2) + "：" + zeroFill(time % 60, 2);
}

/**
 * 分→hh:mm
 * 引数：num
 * 戻り値: Str
 */
export function minutesToKanjiTime(time){
    return Math.floor(time / 60) + "時間" + zeroFill(time % 60, 2) + "分";
}

/**
 * APIに送る際DateだとUTCに変換されてしまうので
 * ISO 8601準拠
 * 引数：Date
 * 戻り値：Str      YYYY-MM-DDThh:mm:ss+09:00
 */
export function isoFormatDate(convertDate){
    let timezone = convertDate.getTimezoneOffset() / 60;    // 東京(+09:00) なら -540
    return zeroFill(convertDate.getFullYear(), 4) + "-" + zeroFill(convertDate.getMonth()+1, 2) + "-" + zeroFill(convertDate.getDate(),2)
     + "T" + zeroFill(convertDate.getHours(), 2) + ":" + zeroFill(convertDate.getMinutes(), 2) + ":" + zeroFill(convertDate.getSeconds(), 2)
     + "+09:00";
     //+ (timezone === 0 ? "Z" : timezone < 0 ? "+" + zeroFill(-timezone, 2) : "-" + zeroFill(timezone, 2)) + ":00";    //TODO:多分不要
}

// 年月日で比較（date<dateだと時間もになるので）
// 0→同じ年月日 1→date1<date2 2→date1>date2
export function compareYMD(date1, date2){
    if (date1.getFullYear() == date2.getFullYear() && date1.getMonth() == date2.getMonth() && date1.getDate() == date2.getDate()){
        return 0;
    } else if(date1.getFullYear() < date2.getFullYear() || (date1.getFullYear() == date2.getFullYear() && date1.getMonth() < date2.getMonth()) || (date1.getFullYear() == date2.getFullYear() && date1.getMonth() == date2.getMonth() && date1.getDate() < date2.getDate())){
        return 1;
    } else {
        return 2;
    }
}

// YYYY-MM-DDの形
export function formatDatabaseDate(date){
    return date.getFullYear() + "-" + zeroFill(date.getMonth()+1, 2) + "-" + zeroFill(date.getDate(), 2);
}

/**
 * 引数：Date
 * 戻り値：Str      YYYY.MM.DD（day）
 */
export function displayDate(convertDate){
    // TODO:constにおく
    const dayOfWeek = ["日", "月", "火", "水", "木", "金", "土"];
    return convertDate.getFullYear() + "." + (convertDate.getMonth()+1) + "." + convertDate.getDate() + "（"+ dayOfWeek[convertDate.getDay()] + "）";
}

/**
 * 引数：Str       YYYY-MM-DD HH:MI:SS
 * 戻り値：Str      YYYY/mm/dd
 */
export function displayDate2(convertDate){
    return convertDate.substring(0, 4)+'/'+Number(convertDate.substring(5, 7))+'/'+Number(convertDate.substring(8, 10));
}

/**
 * 引数：Date
 * 戻り値：Str hh:mm
 */
export function displayTime(convertDate){
    return zeroFill(convertDate.getHours(), 2) + ":" + zeroFill(convertDate.getMinutes(), 2);
}

/**
 * カンマ区切り
 * 引数：num
 * 戻り値: Str      10000 → 10,000
 */
export function commaDelimiter(price){
    if (isNaN(price)) {
        return '';
    }
    return Math.floor(price).toString().replace(/(\d)(?=(\d{3})+$)/g , '$1,');
}

/**
 * カンマ区切り(小数あり)
 * 引数：num
 * 戻り値: Str      10000.01 → 10,000.01
 */
export function commaDelimiterPoint(price){
    if (isNaN(price)) {
        return '';
    }
    let strPrice = price.toString().split(".");
    return strPrice[0].replace(/(\d)(?=(\d{3})+$)/g , '$1,') + (strPrice[1] ? "." + strPrice[1] : "");
}

/**
 * 0埋め
 * IEでpadStartが使えないため
 * 数値.toString().padStart(2, '0')
 * 引数：num, length        1, 3
 * 戻り値：Str              001
 */
export function zeroFill(num, length){
    let returnStr = num.toString();
    let index = returnStr.length;
    while(index < length){
        returnStr = "0" + returnStr;
        index ++;
    }
    return returnStr;
}

/**
 * 単位変換（kt→km）
 * 引数：kt（Number）
 * 戻り値：Number km
 * */
export function ktToKm(kt){
    return Math.round(kt * 1.852);
}

/**
 * 単位変換（分→時分）
 * 引数：分（Number）
 * 戻り値：String　h時間m分形式の文字列
 * */
export function minToHourMin(minutes){
    var h = Math.floor(minutes / 60);
    var m = minutes % 60;
    return (h > 0 ? h + '時間' : '') + (m > 0 ? m + '分' : '');
}

/**
 * 時刻文字列（MM:SS）からDate型を取得
 * 引数：MM:SS（String）
 * 戻り値：時刻がセットされたDate型
 * */
export function strTimeToDate(mmss){
    var date = new Date();
    date.setHours(Number(mmss.split(':')[0]));
    date.setMinutes(Number(mmss.split(':')[1]));
    return date;
}

/**
 * Date型からを時刻文字列（HH）取得
 * 引数：date（Date型）
 * 戻り値：HH文字列
 * */
export function dateToStrHour(date){
    var hh = ('0' + date.getHours()).slice(-2);

    return hh;
}
/**
 * Date型からを時刻文字列（MM）取得
 * 引数：date（Date型）
 * 戻り値：MM文字列
 * */
export function dateToStrMinute(date){
    var mm = ('0' + date.getMinutes()).slice(-2);

    return mm;
}
/**
 * Date型からを時刻文字列（MM:SS）取得
 * * 引数：date（Date型）
 * 戻り値：MM:SS形式文字列
 * */
export function dateToStrTime(date){
    var hh = ('0' + date.getHours()).slice(-2);
    var mm = ('0' + date.getMinutes()).slice(-2);

    return hh + ':' + mm;
}

/**
 * 区分値名取得（性別）
 * 引数：sex（Number）
 * 戻り値：String
 * */
export function getSexText(sex){
    switch (sex) {
        case Const.SEX_MALE.value:
            return Const.SEX_MALE.text
        case Const.SEX_FEMALE.value:
            return Const.SEX_FEMALE.text
    }
}

/**
 * 区分値名取得（使用可能言語）
 * 引数：language（Number）
 * 戻り値：String
 * */
export function getLanguageText(language){
    switch (language) {
        case Const.LANGUAGE_TYPE_JA.value:
            return Const.LANGUAGE_TYPE_JA.text
        case Const.LANGUAGE_TYPE_EN.value:
            return Const.LANGUAGE_TYPE_EN.text
        case Const.LANGUAGE_TYPE_JA_EN.value:
            return Const.LANGUAGE_TYPE_JA_EN.text
    }
}

/**
 * 区分値名取得（フライトタイプ）
 * 引数：frightType（Number）
 * 戻り値：String
 * */
export function getFrightTypeText(frightType){
    switch (frightType) {
        case Const.FRIGHT_TYPE_ROUND_TRIP.value:
            return Const.FRIGHT_TYPE_ROUND_TRIP.text
        case Const.FRIGHT_TYPE_ONE_WAY.value:
            return Const.FRIGHT_TYPE_ONE_WAY.text
        case Const.FRIGHT_TYPE_EXCURSION.value:
            return Const.FRIGHT_TYPE_EXCURSION.text
    }
}

/**
 * 区分値名取得（マッチングステータス（プレイヤ毎））
 * 引数：0:ユーザー承認待/1:同意待/2:辞退/3:同意済
 * 戻り値：String
 * */
export function getMatchingStsPOBText(sts){
    switch (sts) {
        case Const.MATCHING_USER_STATUS_WAIT.value:
            return Const.MATCHING_USER_STATUS_WAIT.text
        case Const.MATCHING_USER_STATUS_DURING.value:
            return Const.MATCHING_USER_STATUS_DURING.text
        case Const.MATCHING_USER_STATUS_CANCEL.value:
            return Const.MATCHING_USER_STATUS_CANCEL.text
        case Const.MATCHING_USER_STATUS_AGREE.value:
            return Const.MATCHING_USER_STATUS_AGREE.text
        case Const.MATCHING_USER_STATUS_CHANGE.value:
            return Const.MATCHING_USER_STATUS_CHANGE.text
    }
}

/**
 * 区分値名取得（承認ステータス）
 * 引数：0:未アップロード/1:認待ち/2:差し戻し/9:承認済
 * 戻り値：String
 * */
export function getApprovalStsText(sts){
    if (typeof value !== 'number') sts = Number(sts);
    switch (sts) {
        case Const.USER_STATUS_UNUPLOAD.value:
            return Const.USER_STATUS_UNUPLOAD.text
        case Const.USER_STATUS_UPLOADED.value:
            return Const.USER_STATUS_UPLOADED.text
        case Const.USER_STATUS_REMANDED.value:
            return Const.USER_STATUS_REMANDED.text
        case Const.USER_STATUS_APPROVED.value:
            return Const.USER_STATUS_APPROVED.text
    }
}

/**
 * 基準値の倍数のうち、絶対値に換算して最も近い値に切り上げられた数値を返す
 * （ExcelのCEILING関数と同様の処理）
 * 引数：数値, 基準値
 * 戻り値：数値
 * */
export function ceiling(number, significance) {
    return Math.ceil(number / significance) * significance;
}

// ObjectをMapに変換
// (DBから受け取った項目をキー指定で取り出すため)
export function convertObjectToMap(key, data) {
    return objectToMap(key, data, false);
}

// ObjectをMapに変換
// (DBから受け取った項目をキー指定で取り出すため)
export function objectToMap(key, data, intKey) {
    let retMap = new Map();
    data.map((data,index) => {
        intKey? retMap.set(Number(data[key]), data) : retMap.set(data[key], data);
    });
    return retMap;
}

/**
 * 同じキーの配列をまとめる
 * Object [
 *  {keyA : 1, keyB : 1},
 *  {keyA : 2, keyB : 1},
 *  {keyA : 3, keyB : 2},
 *  {keyA : 4, keyB : 2},
 * ] 
 * ↓sortDBtoKeyMap("keyB",Object)
 * Map [key 1:[
 *         {keyA : 1, keyB : 1},
 *         {keyA : 2, keyB : 1}
 *        ],
 *      key 2:[
 *         {keyA : 3, keyB : 2},
 *         {keyA : 4, keyB : 2}
 *        ],
 *     ]
 *  */ 
export function sortDBtoKeyMap(key, data) {
    let retMap = new Map();
    data.map((data,index) => {
        if (retMap.has(data[key])){
            retMap.get(data[key]).push(data);
        } else {
            retMap.set(data[key], [data]);
        }
    });
    return retMap;
}
// object[変数]でアクセスできたのでmap不要？
export function sortDBtoKeyObject(key, data) {
    let retObj = {};
    data.map((data,index) => {
        if (retObj[data[key]]){
            retObj[data[key]].push(data);
        } else {
            retObj[data[key]] = [data];
        }
    });
    return retObj;
}

export function pilotFlightTime(time, round=1){
    // * 10 の後にceilすることで小数第二位を切り上げ
	let multiplier = 10;
	for (let index = round; --index;) {
		multiplier *= 10;
	}
    let val = Math.ceil(time / 60 * multiplier) / multiplier;
    return val;
}

export function setInclude(){
    // IEだと使えないため
    if (!String.prototype.includes) {
        Object.defineProperty(String.prototype, 'includes', {
            value: function(search, start) {
            if (typeof start !== 'number') {
                start = 0
            }
            
            if (start + search.length > this.length) {
                return false
            } else {
                return this.indexOf(search, start) !== -1
            }
            }
        })
    }
}

// 切り捨てで単位変換 10,000,000(1000000) → 1000万円
export function japanUnitRound(arg){
    const units = ["", "万", "億", "兆"];
    let cost = Math.floor(Number(arg));
    let remainder = cost;
    let index = 0;
    while (remainder >= 10000) {
        remainder = Math.floor(remainder / 10000);
        index++;
    }
    return commaDelimiter(remainder) + units[index] + "円";
}

export function urlSearchToObject(){
    let pairs = window.location.search.substring(1).split("&"),
    obj = {},
    pair;
  
    pairs.forEach((value, key)=>{
        if ( value === "" ) return;
    
        pair = value.split("=");
        obj[ decodeURIComponent( pair[0] ) ] = decodeURIComponent( pair[1] );
    });
  
    return obj;
}

/**
 * ユーザー名取得
 * 引数：user_infoの名前カラムをキーにもつ連想配列
 * 戻り値：String
 * */
export function getUsername(params) {
    if (params['surname_kanji']) {
        return params['surname_kanji'] + ' ' + params['name_kanji'];
    } else if (params['name']) {
        return params['name'] + ' ' + params['surname'];
    } else {
        return '';
    }
}
// arrayの先頭除去（shift()だと戻り値が先頭要素となるため）
export function arrayHeadRemove(arg){
    let list = arg;
    list.shift(); 
    return list;
}

/**
 * Polyfill for IE
 */
if (!Array.prototype.find) {
  Array.prototype.find = function(predicate) {
    if (this === null) {
      throw new TypeError('Array.prototype.find called on null or undefined');
    }
    if (typeof predicate !== 'function') {
      throw new TypeError('predicate must be a function');
    }
    var list = Object(this);
    var length = list.length >>> 0;
    var thisArg = arguments[1];
    var value;

    for (var i = 0; i < length; i++) {
      value = list[i];
      if (predicate.call(thisArg, value, i, list)) {
        return value;
      }
    }
    return undefined;
  };
}
