//バリデーション頻出
export const VALIDATE_FILE_UPLOAD               = "ファイル形式がJPEG、PNG、PDFいずれかのファイルをアップロードしてください";
export const VALIDATE_FILE_UPLOAD_IMAGE         = "ファイル形式がJPEG、PNGいずれかのファイルをアップロードしてください";
export const VALIDATE_NUMBER_ALPHA_20_LIMIT     = "半角英数20文字までです";
export const VALIDATE_MESSAGE                   = "最大50文字までです";
export const NO_TARGET_TO_SAVE                  = '保存する項目がありません。';
// ログイン　新規会員登録
export const VALIDATE_EMAIL                     = "256文字以内の正しいメールアドレス形式（例 abc@def.com）でご入力ください";
export const VALIDATE_PASSWORD                  = "英字と数字を組み合せた8~20文字をご入力ください";
export const VALIDATE_MATCH_PASSWORD            = "現在と同じパスワードです";
export const VALIDATE_NOT_MATCH_PASSWORD        = "パスワードが一致しません";
export const VALIDATE_NAME                      = "半角英字をご入力ください";
export const VALIDATE_SUR_NAME                  = "半角英字をご入力ください";
export const VALIDATE_EMAIL_NOT_MATCH           = "メールアドレス（確認用）が一致しません";
export const ERROR_CODE = {
    1001 : "Twitterログインに失敗しました",
    1002 : "メールの認証期限が切れました",
    1003 : "既に登録されているメールアドレスです"
};
export const SUCCESS_CODE = {
    1001 : "メールアドレスが変更されました",
};
// ユーザー登録
export const VALIDATE_NAME_KANJI                = "最大50文字までです";
export const VALIDATE_SUR_NAME_KANJI            = "最大50文字までです";
export const VALIDATE_BIRTHDAY                  = "生年月日を選択してください";
export const VALIDATE_LANGUAGE                  = "言語を選択してください";
export const VALIDATE_COUNTRY_MASTER            = "国名を選択してください";
export const VALIDATE_ZIPCODE                   = "ハイフンなしの半角数字7文字でご入力ください";
export const VALIDATE_NOT_FOUND_ZIPCODE         = "お探しの郵便番号がみつかりませんでした";
export const VALIDATE_PREFECTURE                = "都道府県を選択してください";
export const VALIDATE_ADDRESS1                  = "市区町村名・番地は最大130文字までです";
export const VALIDATE_ADDRESS2                  = "建物・部屋番号は最大50文字までです";
export const VALIDATE_TEL                       = "ハイフンなしの半角数字でご入力ください";     //TODO:文字数制限 & 全角→半角
export const VALIDATE_COUNTRY_TEL               = "携帯電話番号の国コードを選択してください";
export const VALIDATE_CNAME_CHANGED_STYLE       = "事業者名称が変更されました。<br/>ファイルをアップロードしてください";
export const VALIDATE_HALF3                     = "半角13文字でご入力ください";

// パイロット登録
export const VALIDATE_SKILL_TYPE_STYLE          = "いずれか1つ以上選択してください";
export const VALIDATE_EXAMINATION_STYLE         = "審査日を選択してください";
export const VALIDATE_EXAMINATION_EXPIRATION_STYLE  = "有効期限を選択してください";
export const VALIDATE_INSPECTION_EXPIRATION_STYLE   = "有効期限を選択してください";
export const VALIDATE_FLIGHTTIME_TOTAL_STYLE    = "整数5桁までです";
export const VALIDATE_FLIGHTTIME_CAPTAIN_STYLE  = "整数5桁までです";
export const VALIDATE_FLIGHT_PRICE_STYLE          = "整数6桁までです";
export const VALIDATE_CHARGE_FEE_STYLE          = "整数6桁までです";
export const VALIDATE_AIRPORT_LETTER_CODE_FOUR_STYLE    = "4レターコードをご記入ください";              //TODO:全角<-->半角制御
export const VALIDATE_AIRCRAFTS_ERROR_STYLE    = "未選択項目がございます";
export const VALIDATE_AIRCRAFTS_ERROR_TEXT_STYLE    = "型式でその他を選ばれた場合は30文字以内でご記入ください";
export const VALIDATE_SKILL_CHANGED_STYLE= "限定事項のファイルをアップロードしてください";
export const VALIDATE_ENGLISHLVL_CHANGED_STYLE= "航空英語能力証明のファイルをアップロードしてください";
export const VALIDATE_PERIOD_CHANGED_STYLE= "有効期間が変更されました。<br/>ファイルをアップロードしてください";
export const PLACEHOLD_MESSAGE= "メッセージ例：\n" +
  "当社所属の運転手は地元出身で雪道の運転に慣れた経験豊富なドライバーです。\n" +
  "お客様の大切な時間を尊重し、安全かつ確実に目的地にご案内いたします。";
// オーナー登録
export const VALIDATE_MESSAGE_STYLE= "最大300文字までです";
export const VALIDATE_SYMBOL_STYLE= "半角英数字4桁です";
export const VALIDATE_MODEL_STYLE= "車両型式を選択してください";
export const VALIDATE_MANUFACTURER_STYLE= "製造者を選択してください";
export const VALIDATE_SERIAL_NUMBER_STYLE= "英数字とハイフン(-)のみ10文字までです";
export const VALIDATE_CLASSIFICATION_STYLE= "選択してください";
export const VALIDATE_CLASSIFICATION_DATE_STYLE= "現在有効な期間を選択してください";
export const VALIDATE_CERTIFICATE_STYLE= "耐空証明有効期間が変更されました。耐空証明書をアップロードしてください";
export const VALIDATE_CERTIFICATE_REG_STYLE= "所有者氏名又は名称が変更されました。車両登録証明書をアップロードしてください";
export const VALIDATE_COMPENSATION_STYLE= "保険内容が変更されました。保険証書をアップロードしてください";
export const VALIDATE_REMARKS_STYLE= "最大200文字までです";
export const VALIDATE_SPEED_STYLE= "整数4桁までです";
export const VALIDATE_FLIGHT_TIME_STYLE= "整数4桁と小数第一位までです";
export const VALIDATE_SEATS_STYLE= "整数2桁までです";
export const VALIDATE_CARGO_STYLE= "整数4桁までです";
export const VALIDATE_REMAINING_TIME_STYLE= "整数4桁までです";
export const VALIDATE_TOTAL_FLIGHT_TIME_STYLE= "整数6桁までです";
export const VALIDATE_CONTRACT_PRICE_STYLE= "整数7桁までです";
export const VALIDATE_OWNER_CHARGE_FEE_STYLE= "整数7桁までです";
export const VALIDATE_OWNER_INSURANCE_STYLE= "整数6桁までです";
export const VALIDATE_OWNER_INSURANCE_DAYS_STYLE= "整数4桁までです";
export const VALIDATE_OWNER_DEATH_INSURANCE_STYLE= "整数6桁と小数第四位までです";
export const VALIDATE_OWNER_COMPENSATION= "整数2桁と小数第八位までです";
export const VALIDATE_PARKING_AREA_STYLE= "停留地を選択してください";
export const VALIDATE_PARKING_AREA_TEXT_STYLE= "その他を選ばれた場合は50文字以内でご記入ください";
export const VALIDATE_PILOTS_LINK= "登録されていない技能証明番号です";
export const VALIDATE_PILOTS_EMP= "オーナーと雇用関係にあるドライバーの登録はできません";
export const VALIDATE_OWNER_NAME= "最大100文字までです";
export const VALIDATE_LICENSE_NUMBER= "「札幌300わ1234」の形式で入力してください。";
export const PLACEHOLD_MESSAGE_O= "メッセージ例：\n" +
  "贅沢な旅の舞台を、心地よい6人乗りレンタカーで演出しませんか？広々とした車内で快適なドライブをお楽しみいただけます。\n" +
  "家族や友達との思い出深い旅行、ビジネスチームとのスムーズな移動に最適な6人乗り車。\n" +
  "優雅なデザインと最新の快適装備が、あなたの旅をより一層特別なものにします。";
export const TODOTODO             = "TODOTODO";

// フライト作成
export const COST_DIFF_AIRCRAFT = "車両情報が変更されました。";
export const NOTHING_AIRCRAFT = "現在利用可能な車両がございません。";
export const MISSING_AIRCRAFT = "選択中の車両が利用不可となりました。";
export const COST_DIFF_PILOT = "ドライバー情報が変更されました。";
export const NOTHING_PILOT = "現在搭乗可能なドライバーがございません。";
export const MISSING_PILOT = "選択中のドライバーが搭乗不可となりました。";

//　マッチング詳細
export const NOT_MATCHING_ACTION = "マッチング情報に更新がございます。お手数ですが今一度ご確認ください。";
export const VALIDATE_DUPLICATE_VIA = "経由地が重複しています。";

export const VALIDATE_CARD_NUMBER = "半角数字でご入力ください";
export const VALIDATE_CARD_MOUNTH_YEAR = "半角数字2桁です";
export const VALIDATE_CARD_CVC = "半角数字3~4桁までです";
export const VALIDATE_PILOT_CANCEL_NO_SHOW = "ドライブ開始予定時刻から1時間を過ぎないとNO SHOWでのキャンセルは行えません。";

// 口座登録
export const VALIDATE_INVALID_BANK_CODE = "無効な銀行コードです";
export const VALIDATE_INVALID_BANK_BRANCH_CODE= "無効な支店コードです";
export const VALIDATE_INVALID_BANK_ACCOUNT_NUMBER= "無効な口座番号です";
export const VALIDATE_INVALID_BANK_ACCOUNT_HOLDER_NAME= "無効な口座名義です";
export const VALIDATE_INVALID_BANK_ACCOUNT_TYPE= "無効な預金種別です";

// 同乗者
export const VALIDATE_EMERGENCY_CONTACT_NAME= "最大100文字までです";
export const VALIDATE_B_RELATIONSHIP= "最大10文字までです";
export const VALIDATE_EMERGENCY_CONTACT_TEL= "ハイフンなしの半角数字でご入力ください";
export const VALIDATE_PASSENGER_NAME= "最大100文字までです";
export const VALIDATE_PASSENGER_TEL= "ハイフンなしの半角数字でご入力ください";
export const VALIDATE_REGISTRATION_NUMBER= "「札幌300わ1234」の形式で入力してください";
export const VALIDATE_DRIVERS_LICENSE_NUMBER= "12桁の半角数字で入力してください";

export const TOOLTIP = {
    usrAuth: "安全保障のための本人確認として、政府発行の身分証明書(ご登録の住所が確認できる運転免許証、パスポート、在留カード)の写真をご提出ください",
    oMsg: "利用者に向けたアピールメッセージを入力してください。車両を所有することになった経緯、普段どんな使い方をしているか等、人となりや背景がわかり親しみがわくような文章が、利用者に安心感を与え好まれます。（最大300文字）",
    pMsg: "利用者に向けたアピールメッセージを入力してください。ドライバーになった経緯、どんなフライトを得意としているか等、人となりや背景がわかり親しみがわくような文章が、利用者に安心感を与え好まれます。（最大300文字）",
    pAuthTech: "お手持ちの技能証明を参考にご入力ください",
    pAuthPhys: "お手持ちの身体検査証を参考にご入力ください",
    pLog: "固定翼あるいは回転翼、またはその両方のログブック最終ページをJPEG、PNGあるいはPDF形式にてアップロードしてください。合計時間がわかるように集計してください",
    pLogPrev: "固定翼あるいは回転翼、またはその両方のログブック最終ページの前ページをJPEG、PNGあるいはPDF形式にてアップロードしてください。合計時間がわかるように集計してください",
    pFeeHour: "一般ユーザと契約する際の操縦に係る時間単価を任意で決定してください。ただし勤務地が自治体を跨ぐため地域別最低賃金の最大値（2020年現在1,013円）における深夜勤務割増分（1,013円×125％=1,267円）未満とならないようにしてください",
    pFeeDay: "操縦以外での準備や待機など拘束時間に対する費用です。一日あたりの単価を任意で設定してください。ただし本項目は0円でも問題ありません",
    cpFeeHour: "一時間あたりのドライバーフィーを「税込み」で記入してください。",
    cpFeeDay: "一日あたりのドライバーフィーになります。事業会社の入力値に下限はありません。事業会社が入力される際は「税込み」で記入してください。",
    pRecruit: "希望される方は航空事業者のリクルート担当者へ向けて情報を公開致します",
    pFare: "交通費はマッチング依頼時点での設定内容が反映されます。交通費を未設定のままマッチングされた場合、交通費は支払われませんのでご注意ください。<br/><br/>マッチング依頼後に交通費の請求をする場合は、マッチング詳細の費用明細画面から、「その他費用」にてボロワに請求してください。<br/>但し、フライト前日にキャンセルされた場合、「その他費用」は支払われません。",
    pMnf: "夜間飛行経歴の有無を選択してください。<br/>安全のため、過去90日間以内の夜間飛行経歴がない場合、日出前、日没後の離着陸を含むマッチング依頼を受けることが出来ません",
    pMnfLog: "夜間飛行の記録があるログブックのページをJPEG、PNGあるいはPDF形式にてアップロードしてください",
    oExpired: "耐空証明書の有効期限が切れている場合、有効期限の入力及び耐空証明書の提出は不要です",
    oAuthReg: "車両登録証明書または耐空証明書に記載の内容を入力してください",
    oAuthAir: "耐空証明書に記載の内容を入力してください",
    oSpeed: "マッチング検索時の到着時間の計算に使用されます<br/>オーナー様の機材における経済的な速度を入力してください",
    oFlightTime: "燃料満タン時の、経済巡航速度での航続時間を入力してください",
    oSeats: "運転席を含む座席数を入力してください",
    oCargo: "利用者が持ち込める荷物重量を入力してください",
    oRemTime: "直近の定例整備までの残り時間を入力してください",
    oSumTime: "製造後総飛行時間を入力してください",
    oDiary: "当該日誌の最終ページをアップロードしてください",
    oTermsAndConditions: "レンタカー貸渡約款をアップロードしてください",
    oFeeHour: "一時間当たりのレンタル費用を「税抜き」で入力してください",
    oFeeDay: "一日あたりの貸出料金を入力してください",
    coFeeHour: "一時間あたりの機材運航費になります。<br/>燃料費などを含め一時間あたりの販売またはレンタル価格を記入してください。<br/>「税抜き」で記入してください。",
    coFeeDay: "1日拘束される可能性が有るためチャージ費用をご入力頂いております。<br/>「税抜き」で記入してください。",
    oAllowP: "事業会社をドライバーとして紐づける場合は、法人番号を指定してください",
    oEmploy: "ドライバーと雇用関係にある場合、道路交通法に抵触するため、許可ドライバーとしてご登録いただけません。<br/>また、別法人である場合でも、経営支配下にある子会社などの車両、ドライバーとのマッチングは法令に抵触する可能性があります。<br/>詳細はお問い合わせください。",
    oBilling: "ドライバ手数料を含んだ実際に利用者が請求される金額(税込)です",
    oCompensation: "航空局の指導により第三者賠償責任保険への加入が義務付けられています。<br/>オーナー様の保険加入が確認できない場合はドライバ補償の加入適用となります。<br/>ドライバ補償を利用しない場合は、ご利用中の保険の補償内容をご入力ください。",
    cCode: "航空局にて登録されている3桁の略号コードを入力してください。<br/>略号がない場合は、ドライバにてコードを指定いたしますので、ご連絡いただけますようお願いいたします。",
    cLanguage: "現地で操縦士が対応できる言語となります。",
    cPilots: "貴社から機長発令可能又は派遣可能なドライバーの技能種類を選択してください。",
    cQualif: "貴社から機長発令可能又は派遣可能なドライバーの保有資格を選択してください。",
    cAircraft: "自家用機を選択した場合は、当該車両の操縦を許可するドライバーの登録が必要となります",
    aAuth: "旅行業登録のない方はドライバまでお問い合わせください。",
    matchingRevoke:"航路詳細の変更を取り下げる場合は、一度「承認を依頼」してから、「取り下げる」操作を行ってください。",
    matchingReject:"確認画面は表示されません。航路変更の「却下」は取り消せませんのでご注意ください。フライト内容については、再度ドライバーとご相談ください。",
    matchingDelegate:"チェックをつけることで、マッチング成立後にドライバーによって利用時間等の変更がされた際、自動で承認されるようになります。<br/>自動承認をしない場合は、チェックを外したままにしてください。",
    explainCVC : "カード裏面に記載されている3桁の数字をご入力ください。"
};
