import React from 'react';
import './css/Dialog.css';
import Modal from './Modal.js';

/**
 * ダイアログのベース
 */
export function DialogBase(props){
    return (
        <Modal>
            <div className="dialogBackground">
                <div className="dialogMain">
                    {props.children}
                </div>
            </div>
        </Modal>
    )
}

/**
 * 基本ダイアログ
 */
export function Dialog(props){
    return (
        <DialogBase>
            <div className="dialogTitle">{props.title}</div>
            <div className="dialogHr" />
            <div className="dialogBody">{props.body}</div>
            {props.miniBody ? <div className="dialogMinibody">{props.body}</div> : null}
            <div style={{display: "flex", justifyContent: "center", flexWrap: "wrap"}}>{props.children}</div>
        </DialogBase>
    )    
}

// ダイアログ
export const ERROR_CODE = 10000;
export const COMMON_ERROR = {title:"エラー"};

export const DIALOG_CODE = {
    // ログイン, 新規会員登録
    login : {
        // 異常系
        [ERROR_CODE] : COMMON_ERROR,
        10001 : {title:"入力内容をご確認ください", body: "入力されたメールアドレスとパスワードではご登録がありません"},
        10002 : {title:"メールアドレス認証が未完了です", body: <div>新規会員登録メールアドレス認証が未完了です。<br />登録メールアドレスに送信された本登録メールに記載のURLをクリックして、<br />メールアドレスの認証を行ってください。</div>},
        1001 : {title:"エラー", body: "Twitterログインに失敗しました"},
        1002 : {title:"メールアドレス認証の有効期限が切れています", body: <div>認証URLの有効期限は受信から24時間です。<br />恐れ入りますが、再度新規会員登録を行ってください。</div>},
        1003 : {title:"エラー", body: "既に登録されているメールアドレスです"},
        // 正常系
        20001 : {title:"メール送信完了", body:<div>ユーザ本登録のメールを送信しました。<br/>30分以内に届かない場合は、サポートまでお問い合わせください。</div>},
        2001 : {title:"", body:"メールアドレスが変更されました"},
    },
    // フライト作成
    flight : {
        [ERROR_CODE] : COMMON_ERROR,
        10001 : {title:"マッチングの申し込み", body: "マッチングを申し込むにはユーザのご登録が必要です。"},
        10002 : {title:"マッチングの取りやめ", body: "入力された内容は破棄されますが、よろしいですか？"},
        10003 : {title:"ユーザー情報が登録されておりません", body: "ユーザー情報が登録されているユーザーのみ閲覧ができます。"},
        10004 : {title:"現在利用可能な車両がありません", body: <React.Fragment>指定された条件でマッチング可能な車両やドライバーがありません。<br />登録された車両の最大航続距離を超える航路を条件に指定された場合もマッチングできません。<br />フライト条件についてのご相談は、<a style={{color:"blue"}} href="mailto:airshare_support@airshare.co.jp" onClick={goContactMissingAircraft}>こちら</a>からお問い合わせください。</React.Fragment>},
        10005 : {title:"オファー申し込みの締め切り時間が過ぎました", body: <React.Fragment>AM12:00を過ぎると翌日のマッチングフライトオファーはできません。<br />申し訳ございませんが、別日程でのマッチング作成をお願いいたします。</React.Fragment>},
        10006 : {title:"オファー申し込みの締め切り時間が過ぎました", body: <React.Fragment>AM09:00を過ぎると夜間飛行を伴う翌日のマッチングフライトオファーはできません。<br />申し訳ございませんが、別日程でのマッチング作成をお願いいたします。</React.Fragment>},
        10007 : {title:"指定された条件でのフライトはできません", body: <React.Fragment>飛行機の利用が出来ない空港が選択されています。<br />条件を変えてお探しください。</React.Fragment>},
        10008 : {title:"指定された条件でのフライトはできません", body: <React.Fragment>ヘリコプターの利用が出来ない空港が選択されています。<br />条件を変えてお探しください。</React.Fragment>},
        10009 : {title:"クーポンがご利用できません", body: "現在利用可能なクーポンがございません。"},
        10010 : {title:"ドライバー資格の有効期限後のフライトです", body: <React.Fragment>選択されたドライバーは、ご希望のフライト日時点で一部資格が有効期限切れとなります。<br />このままマッチング依頼は行えますが、フライト予定日の3日前までにドライバーによる<br />資格情報の更新がされない場合、自動でキャンセルとなりますのでご了承ください。<br />（※この場合のキャンセル費用はかかりません）</React.Fragment>},
        10011 : {title:"オファー申し込みの締め切り時間が過ぎました", body: <React.Fragment>出発時刻を過ぎています。<br />申し訳ございませんが、別日程でのマッチング作成をお願いいたします。</React.Fragment>},
    },
    // マッチング
    matching : {
        [ERROR_CODE] : COMMON_ERROR,
        20000 : {title: "", body:"変更が保存されました。"},
    },
    coupon : {
        [ERROR_CODE] : COMMON_ERROR,
    },
    faq : {
        [ERROR_CODE] : COMMON_ERROR,
    },
};


function goContactMissingAircraft(e) {
    e.preventDefault();
    var body = `
【お問合せ内容】
　
ご希望の選択条件指定時に利用可能な車両・ドライバーが出てこない等、マッチング可能な条件のご相談、お問い合わせは
以下フォームにご希望の条件をご記入の上、お問合せください。
　[希望フライト条件]
　　利用人数：
　　利用日時：
　　出発地：
　　目的地１：
　　最終目的地：出発地と同じ（異なる場合は削除の上記載して下さい）
`;
    var href = "airshare_support@airshare.co.jp";
    href += "?subject="+encodeURI("【ドライバ】フライト条件についてのお問い合わせ");
    href += "&body="+encodeURI(body);
    window.location.href = "mailto:"+href;
    return false;
  }
