import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './css/Dialog.css';
import * as Const from '../Const.js';
import Button from './Button.js';
import Modal from './Modal.js';

/**
 * ダイアログ表示用のコンポーネント.
 */
class Dialog extends Component {
    //コンストラクタ
    constructor(props) {
        super(props);

        //入力情報設定
        if (props.type < 100) {
            this.state = {
                id          : props.id,
                type        : props.type,
                title       : props.title,
                remarks     : props.remarks !== undefined ? props.remarks : "",
                button      : props.button,
                callback    : props.callback,
                state       : false,
                base_w      : 0,
                base_h      : 0,
                cover_id    : "dialog_" + props.id,
                cover_l     : 0,
                cover_t     : 0,
                interval    : null
            };
        }
        else {
            if (props.type === Const.DIALOG_GENERIC_ERROR) {
                var button = props.button;
                if (button === null) {
                    button = [{
                                id                  : Const.DIALOG_GENERIC_ERROR_BUTTON,
                                value               : "戻る",
                                classname           : "buttonshape buttondialog buttongray",
                                disabled            : false,
                                state               : true,
                                callback            : props.callback,
                                interval            : null
                             }];
                }
                this.state = {
                    id          : props.id,
                    type        : props.type,
                    title       : props.title !== null && props.title !== undefined && props.title !== "" ? props.title : "システムエラーが発生しております。",
                    remarks     : props.remarks !== undefined ? props.remarks : "",
                    button      : button,
                    callback    : props.callback,
                    state       : false,
                    base_w      : 0,
                    base_h      : 0,
                    cover_id    : "dialog_" + props.id,
                    cover_l     : 0,
                    cover_t     : 0,
                    interval    : null
                };
            }
        }
    }

    //ウィンドウの幅高を取得する
    getWindowSize() {
        var w = window;
        var d = document;
        var e = d.documentElement;
        var g = d.getElementsByTagName('body')[0];
        var width = w.innerWidth || e.clientWidth || g.clientWidth;
        var height = w.innerHeight|| e.clientHeight|| g.clientHeight;
        return {
            w: width,
            h: height
        }
    }

    //ダイアログの幅高を取得する
    getDialogSize() {
        var d = document.getElementById(this.state.cover_id);
        var r = d.getBoundingClientRect();
        var w = r.width;
        var h = r.height;
        return {
            w: w,
            h: h
        }
    }

    //コンポーネントが画面に配置されたときに実行されるイベント
    componentDidMount() {
        var size = this.getWindowSize();

        var size_w = parseInt(size.w + 1, 10);
        size_w += size_w % 2;

        var size_h = parseInt(size.h + 1, 10);
        size_h += size_h % 2;

        this.setState({base_w : size_w});
        this.setState({base_h : size_h});

        var interval = setInterval(()=> {
            var size = this.getWindowSize();
            var size_w = parseInt(size.w + 1, 10);
            size_w += size_w % 2;
            var size_h = parseInt(size.h + 1, 10);
            size_h += size_h % 2;


            var base = this.getDialogSize();
            var base_w = parseInt(base.w + 1, 10);
            base_w += base_w % 2;
            var base_h = parseInt(base.h + 1, 10);
            base_h += base_h % 2;


            this.setState({base_w : size_w});
            this.setState({base_h : size_h});

            this.setState({cover_l : size_w / 2.0 - base_w / 2.0});
            this.setState({cover_t : size_h / 2.0 - base_h / 2.0});

            this.setState({state: true});
        }, 100);
        this.setState({interval: interval});
    }

    //コンポーネントが画面から削除されるときに実行されるイベント
    componentWillUnmount() {
        clearInterval(this.state.interval);
    }

    //画面描画
    render() {
        var baseStyle = {
            visibility  : this.state.state === true ? "visible" : "hidden",
            width       : this.state.base_w,
            height      : this.state.base_h
        };
        var coverStyle = {
            left    : this.state.cover_l,
            top     : this.state.cover_t
        };
        var bodyDisp = this.props.type !== Const.DIALOG_GENERIC_ERROR && this.props.body !== null && this.props.body !== undefined && this.props.body !== "" ? this.props.body : "ご不便をおかけいたしまして大変申し訳ございませんが、しばらく時間をおいてから再度お試しください。";
        var titleDisp = this.props.title;
        if (this.props.type === Const.DIALOG_GENERIC_ERROR && !this.props.title) {
            titleDisp = "システムエラーが発生しております。";
        }
        var remarksDisp = this.props.remarks || "";
        var buttonDisp = this.props.button;
        if (!buttonDisp) {
            buttonDisp = [{
                id                  : Const.DIALOG_GENERIC_ERROR_BUTTON,
                value               : "戻る",
                classname           : "buttonshape buttondialog buttongray",
                disabled            : false,
                state               : true,
                callback            : this.props.callback,
                interval            : null
            }];
        }
        return (
            <Modal>
                {(() => {
                    if (this.state.type !== Const.DIALOG_THREE) {
                        return (
                            <div className='dialogBase' style={baseStyle}>
                                <div id={this.state.cover_id} className='dialogCover' style={coverStyle}>
                                    <div className="dialogInCover">
                                        {titleDisp.split("<br/>").map(function(m,i) { return (<p key={"dialog_title_" + i} className="dialotTextLine">{m}</p>); })}
                                        <hr className="dialogHr" />
                                        <div className="dialogText">
                                            {bodyDisp.split("<br/>").map(function(m,i) { return (<p key={"dialog_title_" + i} className="dialotTextLine">{m}</p>); })}
                                            {remarksDisp.split("<br/>").map(function(m,i) { return (<p key={"dialog_title_" + i} className="dialotTextLine"><font size="3">{m}</font></p>); })}
                                        </div>
                                        {this.props.addContent}
                                        {buttonDisp.map(function(data, i) {
                                            if (data.state === true) {
                                                return <Button {...data} key={data.id} />;
                                            }
                                            else {
                                                return <React.Fragment key={data.id} />;
                                            }
                                        })}
                                    </div>
                                </div>
                            </div>
                        );
                    }
                    else {
                        return (
                            <div className='dialogBase' style={baseStyle}>
                                <div id={this.state.cover_id} className='dialogCover' style={coverStyle}>
                                    <div className="dialogInCover">
                                        {titleDisp.split("<br/>").map(function(m,i) { return (<p key={"dialog_body_" + i} className="dialotTextLine">{m}</p>); })}
                                        <Button {...buttonDisp[0]} key={buttonDisp[0].id} />
                                        <hr className="dialogHr" />
                                        <div className="dialogText">
                                            {bodyDisp.split("<br/>").map(function(m,i) { return (<p key={"dialog_body_" + i} className="dialotTextLine">{m}</p>); })}
                                        </div>
                                        <Button {...buttonDisp[1]} key={buttonDisp[1].id} />
                                        <Button {...buttonDisp[2]} key={buttonDisp[2].id} />
                                    </div>
                                </div>
                            </div>
                        );
                    }
                })()}
            </Modal>
        );
    }
}

//Dialogクラスに対する引数
Dialog.propTypes = {
    id          : PropTypes.number.isRequired,
    type        : PropTypes.number.isRequired,
    title       : PropTypes.string,
    body        : PropTypes.string,
    button      : PropTypes.array,
    addContent  : PropTypes.object,             // 例：<div></div>
}

export default Dialog;

