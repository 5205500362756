//テキスト入力関連
//テキスト入力イベント種別
export const EVENT_INPUT_BLUR                   = 1;
export const EVENT_INPUT_CHANGE                 = 2;
export const EVENT_INPUT_FOCUS                  = 3;

//テキスト入力のタイプ
export const TEXT_TYPE_TEXT                     = "text";
export const TEXT_TYPE_PASSWORD                 = "password";
export const TEXT_TYPE_NUMBER                   = "number";

//テキスト入力枠のバリデート
export const TEXT_VALIDATE_LENGTH_MIN           = 1;
export const TEXT_VALIDATE_LENGTH_MAX           = 2;

export const TEXT_VALIDATE_KIND_NUMBER          = 11;
export const TEXT_VALIDATE_KIND_ALPHA           = 12;
export const TEXT_VALIDATE_KIND_NUMBER_ALPHA    = 13;
export const TEXT_VALIDATE_KIND_ASCII           = 14;
export const TEXT_VALIDATE_KIND_MAIL            = 15;
export const TEXT_VALIDATE_KIND_PASSWORD        = 16;
export const TEXT_VALIDATE_FIRST_POINT          = 17;
export const TEXT_VALIDATE_KIND_NUMBER_ALPHA_HYPHEN = 18;
export const TEXT_VALIDATE_FOURTH_POINT          = 19;
export const TEXT_VALIDATE_EIGHTH_POINT          = 20;
export const TEXT_VALIDATE_KIND_SIGNED_NUMBER    = 21;
export const TEXT_VALIDATE_SECOND_POINT_HALF     = 22;
export const TEXT_VALIDATE_KIND_LICENSE_NUMBER     = 23;

//テキスト入力枠のステータス
export const TEXT_STATUS_INITIALIZE             = 1;
export const TEXT_STATUS_NORMAL                 = 2;
export const TEXT_STATUS_ERROR                  = 3;
export const TEXT_STATUS_READONLY               = 4;
export const TEXT_STATUS_HIDDEN                 = 5;


//セレクトボックス入力関連
//セレクトボックスイベント種別
export const SELECT_INITIALIZE                  = 1;
export const SELECT_CHANGE                      = 2;

//セレクトボックスのタイプ
export const SELECT_TYPE_NORMAL                 = "normal";
export const SELECT_TYPE_GROUP                  = "group";              //optgroupを利用する時
export const SELECT_TYPE_YEAR                   = "year";
export const SELECT_TYPE_MONTH                  = "month";
export const SELECT_TYPE_DAY                    = "day";
export const SELECT_TYPE_YEAR_OVER_10           = "year_over_10";

//セレクト入力枠のステータス
export const SELECT_STATUS_NORMAL               = 1;
export const SELECT_STATUS_ERROR                = 2;
export const SELECT_STATUS_READONLY             = 3;
export const SELECT_STATUS_HIDDEN               = 4;


//ラジオボタン関連
//ラジオボタンイベント種別
export const RADIO_CHANGE                       = 1;

//ラジオボタンのステータス
export const RADIO_STATUS_INITIALIZE             = 1;
export const RADIO_STATUS_NORMAL                 = 2;
export const RADIO_STATUS_ERROR                  = 3;


//チェックボックス関連
//チェックボックスイベント種別
export const CHECKBOX_CHANGE                    = 1;

//チェックボックスのタイプ
export const CHECKBOX_TYPE_NORMAL               = "normal";
export const CHECKBOX_TYPE_NO_VALIDATE          = "novalidate";

//チェックボックスのステータス
export const CHECKBOX_STATUS_INITIALIZE         = 1;
export const CHECKBOX_STATUS_NORMAL             = 2;
export const CHECKBOX_STATUS_ERROR              = 3;
export const CHECKBOX_STATUS_READONLY           = 4;
export const CHECKBOX_STATUS_HIDDEN             = 5;


//ダイアログ関連
//ダイアログイベント種別
export const EVENT_CLICK_BUTTON                 = 1;

//ダイアログの種類
export const DIALOG_ONE                         = 1;
export const DIALOG_TWO                         = 2;
export const DIALOG_THREE                       = 3;
export const DIALOG_GENERIC_ERROR               = 101;

//2ボタン、3ボタン用
export const DIALOG_BUTTON_LEFT                 = 1;
export const DIALOG_BUTTON_MIDDLE               = 2;
export const DIALOG_BUTTON_RIGHT                = 3;

//1ボタン用
export const DIALOG_BUTTON_ONE                  = 1;

//汎用ダイアログからのCBのID
export const DIALOG_GENERIC_ERROR_BUTTON        = "generic_dialog_button_1";

//ToolTip関連
//ToolTipイベント種別
export const TOOLTIP_CLICK_BUTTON               = 1;



//アップロード関連
//アップロードのタイプ
export const UPLOAD_TYPE_NORMAL                 = "normal";
export const UPLOAD_TYPE_NO_VALIDATE            = "novalidate";

//アップロードのステータス
export const UPLOAD_STATUS_INITIALIZE           = 1;
export const UPLOAD_STATUS_CHANGE               = 2;
export const UPLOAD_STATUS_REMOVE               = 3;
export const UPLOAD_STATUS_HIDDEN               = 4;
export const UPLOAD_STATUS_ERROR                = 5;

//アップロードのイベント種別
export const UPLOAD_CHANGE                      = 1;

//ファイル拡張子
export const UPLOAD_ACCEPT_TYPE_IMAGE             = '.jpeg, .JPEG, .png, .PNG, .jpg, .JPG';
export const UPLOAD_ACCEPT_TYPE_DEFAULT           = UPLOAD_ACCEPT_TYPE_IMAGE  + ', .pdf, .PDF';


//旧User.js残
export const DASHBOARD_SCROLL_TOP                       = 2;
export const DASHBOARD_SCROLL_BOTTOM                    = 3;
export const DASHBOARD_SCROLL_RETURN                    = 4;

export const DASHBOARD_TITLE_TOP                        = "AirShare";
export const DASHBOARD_TITLE_LOGIN                      = "ログイン";
export const DASHBOARD_TITLE_DASHBOARD                  = "ダッシュボード";
export const DASHBOARD_TITLE_INFORMATION_USER           = "ユーザー情報";
export const DASHBOARD_TITLE_REGISTRATION_USER          = "ユーザー登録";
export const DASHBOARD_TITLE_EDIT_USER                  = "ユーザー編集";
export const DASHBOARD_TITLE_EDIT_EMAIL                 = "メールアドレス変更";
export const DASHBOARD_TITLE_EDIT_PASSWORD              = "パスワード変更";
export const DASHBOARD_TITLE_EDIT_BANK_ACCOUNT          = "振込口座情報変更";
export const DASHBOARD_TITLE_INFORMATION_PILOT          = "ドライバー情報";
export const DASHBOARD_TITLE_REGISTRATION_PILOT         = "ドライバー登録";
export const DASHBOARD_TITLE_EDIT_PILOT                 = "ドライバー編集";
export const DASHBOARD_TITLE_INFORMATION_OWNER          = "オーナー情報";
export const DASHBOARD_TITLE_REGISTRATION_OWNER         = "オーナー登録";
export const DASHBOARD_TITLE_EDIT_OWNER                 = "オーナー編集";
export const DASHBOARD_TITLE_LANDING_PAGE               = "ようこそ";

export const DASHBOARD_MOVE_FRAME_BACK                  = 100;


//通信関連
//ドメイン
export const DOMAIN                             = 'https://driva.airshare.co.jp/api/public/api/';
export const DOMAIN_RESOURCE                    = 'https://driva.airshare.co.jp/';
//メッセージチャットURLプレフィックス 
export const CHAT_URL                           = DOMAIN_RESOURCE + 'chat/client';
export const AIRPORT_PHOTO_URL                  = DOMAIN_RESOURCE + 'assets/airport_photo/';
export const TERMS_URL                          = 'https://pay.jp/legal/tos-payouts-user';
export const DOCUMENT_URL                       = DOMAIN_RESOURCE + 'assets/documents/';
// export const TRAVELOGUE_URL                     = DOMAIN_RESOURCE + 'assets/travelogue/';
export const DOWNLOAD_IMG_URL                   = CHAT_URL +'/icon_122710_48.png';
export const FAQ_CATEGORY_ICON_URL              = DOMAIN_RESOURCE + 'assets/FAQ/';

//通信URL
export const CONNECT_TYPE_LOGIN                 = 'user/login';
export const CONNECT_TYPE_GET_MASTER_USER       = 'user/getMaster';
export const CONNECT_TYPE_REGISTRATION_USER     = 'user/create';
export const CONNECT_TYPE_EDIT_USER             = 'user/update';
export const CONNECT_TYPE_EDIT_MAIL             = 'user/update_email';
export const CONNECT_TYPE_EDIT_PASSWORD         = 'user/update_password';
export const CONNECT_TYPE_REISSUE_PASSWORD      = 'user/reissue_password';
export const CONNECT_TYPE_GET_USER_LIST         = 'user/list';
export const CONNECT_TYPE_DELETE_USER           = 'user/delete';
export const CONNECT_TYPE_GET_MASTER_PILOT      = 'pilot/getMaster';
export const CONNECT_TYPE_GET_PILOT_LIST        = 'pilot/list';
export const CONNECT_TYPE_DELETE_PILOT       	= 'pilot/delete';
export const CONNECT_TYPE_PILOTDRAFT_SEARCH     = 'pilotdraft/search';
export const CONNECT_TYPE_PILOTDRAFT_CREATE     = 'pilotdraft/create';
export const CONNECT_TYPE_PILOTDRAFT_DELETE     = 'pilotdraft/delete';
export const CONNECT_TYPE_PILOTDRAFT_REGIST     = 'pilotdraft/update';
export const CONNECT_TYPE_GET_AIRPORT           = 'ownerdraft/list_airport';
export const CONNECT_TYPE_OWNERDRAFT_SEARCH     = 'ownerdraft/search';
export const CONNECT_TYPE_OWNERDRAFT_CREATE     = 'ownerdraft/create';
export const CONNECT_TYPE_OWNERDRAFT_DELETE     = 'ownerdraft/delete';
export const CONNECT_TYPE_OWNERDRAFT_REGIST     = 'ownerdraft/update';
export const CONNECT_TYPE_GET_OWNER_LIST        = 'aircraft/list';
export const CONNECT_TYPE_MASTER_LIST           = "master/list";
export const CONNECT_TYPE_UPLOAD_FILE_USER      = 'user/uploadFiles';
export const CONNECT_TYPE_GET_CUSER             = 'cuser/search';
export const CONNECT_TYPE_REGISTRATION_CUSER    = 'cuser/create';
export const CONNECT_TYPE_EDIT_CUSER            = 'cuser/update';
export const CONNECT_TYPE_GET_PARENT_CUSER      = 'cuser/parent_cuser';
export const CONNECT_TYPE_GET_CHILD_CUSER_LIST  = 'cuser/child_cuser_list';
export const CONNECT_TYPE_GET_CHILD_CUSER_SEARCH = 'cuser/child_cuser_search';
export const CONNECT_TYPE_GET_CHILD_CUSER_UPDATE = 'cuser/child_cuser_update';

export const CONNECT_TYPE_DEL_CARD              = 'payjp/del_card';

export const CONNECT_TYPE_GET_MAX_SEATS         = 'flight/getMaxSeats';
export const CONNECT_TYPE_GET_FLIGHT_AIRPORTS   = 'flight/getAirports';
export const CONNECT_TYPE_GET_FLIGHT_PARKING_AIRCRAFTS = 'flight/getParkingAircrafts';
export const CONNECT_TYPE_GET_FLIGHT_PLAN_AIRCRAFTS = 'flight/getFlightPlanAircrafts';
export const CONNECT_TYPE_GET_FLIGHT_PLAN_PILOTS = 'flight/getFlightPlanPilots';
export const CONNECT_TYPE_GET_COUPONS           = 'flight/getCoupons';
export const CONNECT_TYPE_MATCHING_CHECK        = 'flight/matchingCheck';
export const CONNECT_TYPE_MATCHING_OFFER        = 'flight/matchingOffer';
export const CONNECT_TYPE_EDIT_MATCHING         = "matching/editMatching";
export const CONNECT_TYPE_EDIT_MATCHING_COST    = "matching/editMatchingCost";

export const CONNECT_TYPE_AGREE_MATCHING        = "matching/agreeMatching";
export const CONNECT_TYPE_CANCEL_MATCHING       = "matching/cancelMatching";
export const CONNECT_TYPE_REFUSE_MATCHING       = "matching/refuseMatching";
export const CONNECT_TYPE_REJECT_MATCHING       = "matching/rejectMatching";
export const CONNECT_TYPE_COMPLETE              = "matching/complete";
export const CONNECT_TYPE_CARD                  = "matching/card";
export const CONNECT_TYPE_CHARGE                = "matching/charge";
export const CONNECT_TYPE_GET_TENANT            = "matching/getTenant";
export const CONNECT_TYPE_UPDATE_TENANT         = "matching/updateTenant";
export const CONNECT_TYPE_GET_COUPON            = "matching/getCoupon";

export const CONNECT_TYPE_LIST_MATCHING         =   "matching/list";
export const CONNECT_TYPE_LIST_MATCHING_SUMMARY =   "matching/summary";

export const CONNECT_TYPE_CREATE_MESSAGE        =   "message/create";
export const CONNECT_TYPE_LIST_MESSAGE          =   "message/list";
export const CONNECT_TYPE_UPDATE_MESSAGE        =   "message/update";
export const CONNECT_TYPE_DELETE_MESSAGE        =   "message/delete";
export const CONNECT_TYPE_MARKASREAD_MESSAGE    =   "message/markasread";
export const CONNECT_TYPE_LIST_NEWS             =   "news/list";

export const CONNECT_TYPE_AUTH_LIST             = "matching/authList";
//郵便番号検索
export const CONNECT_TYPE_ADDRESS               = 'user/search_zip';

export const CONNECT_SUCCESS_CODE200            = 200;

//通信ステータス
export const CONNECT_NONE                       = 0;
export const CONNECT_START                      = 1;
export const CONNECT_SUCCESS                    = 2;
export const CONNECT_ERROR                      = 3;

//SNS
export const SNS_APIKEY_FACEBOOK                = '748089922293233';
export const SNS_APIKEY_GOOGLE                  = '235042432203-jlls8c15g0mgdrap6pantv1o579qbu33.apps.googleusercontent.com';

//PAY.jp
//export const PAY_JP_PUB_KEY                     = 'pk_test_ae4b26024f8bf8d8d91762fb'; // テスト公開鍵
export const PAY_JP_PUB_KEY                     = 'pk_live_1d9915d0d68a82aa21a6b8be'; // 本番公開鍵

//エラーコード
export const CONNECT_CODE_NOLOGIN               = 12;

//承認ステータス
export const USER_STATUS_UNUPLOAD               = {value: 0, text: '【未アップロード】'};
export const USER_STATUS_UPLOADED               = {value: 1, text: '【承認待ち】', text_draft: '【アップロード済】'};
export const USER_STATUS_REMANDED               = {value: 2, text: '【差し戻し】'};
export const USER_STATUS_APPROVED               = {value: 9, text: '【承認済】'};

//デフォルト表示国コード
export const USER_DEFAULT_COUNTRY_CODE          = 392; // 日本

// 国コード：日本
export const JAPAN_COUNTRY_CODE                 = 392;

// その他コード
export const MASTER_EXTRA_CODE                  = {value: '99999', label: 'その他'};

// ユーザー種別
export const USER_TYPE_GENERAL                  = '0';
export const USER_TYPE_COWNER                   = '1';
export const USER_TYPE_CBORROWER                = '2';

//フライト作成
export const FLIGHT_TYPE_CREATEFLIGHT           =0;
export const FLIGHT_TYPE_CREATEROUND            =2;

export const CREATEFLIGHT_SELECT_SEATS          =0;
export const CREATEFLIGHT_SELECT_DATE           =1;
export const CREATEFLIGHT_SELECT_ROUTE          =2;
export const CREATEFLIGHT_SELECT_AIRCRAFT       =3;
export const CREATEFLIGHT_SELECT_PILOT          =4;

export const CREATEROUND_SELECT_TIME            =5;
export const CREATEFLIGHT_SELECT_COUPON         =6;

export const LIMIT_CALENDER_DATE                = 90;
export const HOUR_LIST                          = [{id: '00', value: '00'}, {id: '01', value: '01'}, {id: '02', value: '02'},
                                                  {id: '03', value: '03'}, {id: '04', value: '04'}, {id: '05', value: '05'},
                                                  {id: '06', value: '06'}, {id: '07', value: '07'}, {id: '08', value: '08'},
                                                  {id: '09', value: '09'},{id: '10', value: '10'},{id: '11', value: '11'},
                                                  {id: '12', value: '12'},{id: '13', value: '13'},{id: '14', value: '14'},
                                                  {id: '15', value: '15'},{id: '16', value: '16'},{id: '17', value: '17'},
                                                  {id: '18', value: '18'},{id: '19', value: '19'},{id: '20', value: '20'},
                                                  {id: '21', value: '21'},{id: '22', value: '22'},{id: '23', value: '23'}];

export const MINUTE_LIST                        = [{id  : '00', value : '00'}, {id  : '05', value : '05'},
                                                  {id  : '10', value : '10'},  {id  : '15', value : '15'},
                                                  {id  : '20', value : '20'},  {id  : '25', value : '25'},
                                                  {id  : '30', value : '30'},  {id  : '35', value : '35'},
                                                  {id  : '40', value : '40'},  {id  : '45', value : '45'},
                                                  {id  : '50', value : '50'},  {id  : '55', value : '55'},
                                                  ];
//                                                    {id  : '10', value : '10'}, DB UTC
export const DB_UTC                             = "+09:00";
//フライトのタイプ
export const FRIGHT_TYPE_ROUND_TRIP             = {value: '0', text: '移動フライト航路'};
export const FRIGHT_TYPE_ONE_WAY                = {value: '1', text: '移動フライト航路'};
export const FRIGHT_TYPE_EXCURSION              = {value: '2', text: '遊覧フライト航路'};

//  airport_master.landing_status
export const AIPORT_LANDING_AIRCRAFT = 0;
export const AIPORT_LANDING_HELI = 1;
export const AIPORT_LANDING_NO = 2;
//  airport_master.private_~~~~status
export const AIPORT_PRIVATE_STATUS_ALL = 0;         // 自家用機 / 事業用機
export const AIPORT_PRIVATE_STATUS_BUSINESS = 1;    // 　　　　   事業用機
export const AIPORT_PRIVATE_STATUS_PRIVATE = 2;     // 自家用機
export const AIPORT_PRIVATE_STATUS_NO = 3;          // 利用不可

//性別
export const SEX_MALE                           = {value: '1', text: '男性'};
export const SEX_FEMALE                         = {value: '2', text: '女性'};

//使用可能言語
export const LANGUAGE_TYPE_JA                   = {value: '1', text: '日本語'};
export const LANGUAGE_TYPE_EN                   = {value: '2', text: 'English'};
export const LANGUAGE_TYPE_JA_EN                = {value: '3', text: '日本語・English'};

//航空英語能力証明
export const ENGLISH_CERTIFICATION_LVL          = [
                                                    {value: '4', label: '４', needPeriod: true},
                                                    {value: '5', label: '５', needPeriod: true},
                                                    {value: '6', label: '６', needPeriod: false},
                                                ];

//航空機種別
export const AIRCRAFT_TYPE_AIRPLANE             = "1";
export const AIRCRAFT_TYPE_HELICOPTER           = "2";

//マッチングステータス
export const MATCHING_STATUS_WAIT               = {value: '0', text: 'ユーザー承認待'};
export const MATCHING_STATUS_DURING              = {value: '1', text: 'マッチング中'};
export const MATCHING_STATUS_DEAL             = {value: '2', text: '成立'};
export const MATCHING_STATUS_CANCEL               = {value: '3', text: '不成立'};
export const MATCHING_STATUS_FINISH              = {value: '4', text: 'ドライブ完了'};
export const MATCHING_STATUS_STOP                  = {value: '5', text: 'マッチングキャンセル'};

//マッチングステータス（P,O,B）
export const MATCHING_USER_STATUS_WAIT           = {value: '0', text: 'ユーザー承認待'};
export const MATCHING_USER_STATUS_DURING              = {value: '1', text: '未承認'};
export const MATCHING_USER_STATUS_CANCEL           = {value: '2', text: '辞退'};
export const MATCHING_USER_STATUS_AGREE             = {value: '3', text: '承認済'};
export const MATCHING_USER_STATUS_CHANGE            = {value: '4', text: '計画変更中'};
// 履歴ステータス
export const MATCHING_CHANGE_STATUS_NEW              = {value: '0', text: '新規作成'};
export const MATCHING_CHANGE_STATUS_CHANGE           = {value: '1', text: 'P変更中'};
export const MATCHING_CHANGE_STATUS_OFFER            = {value: '2', text: 'P申請中'};
export const MATCHING_CHANGE_STATUS_OWNER_AGREE      = {value: '3', text: 'オーナー承認'};
export const MATCHING_CHANGE_STATUS_BORROWER_AGREE   = {value: '4', text: 'ボロワ承認'};
export const MATCHING_CHANGE_STATUS_PILOT_CANCEL     = {value: '5', text: 'ドライバーキャンセル'};
export const MATCHING_CHANGE_STATUS_OWNER_CANCEL     = {value: '6', text: 'オーナー却下'};
export const MATCHING_CHANGE_STATUS_BORROWER_CANCEL  = {value: '7', text: 'ボロワ却下'};
export const MATCHING_CHANGE_STATUS_CANCEL           = {value: '8', text: 'キャンセル'};
export const MATCHING_CHANGE_STATUS_NO               = {value: '9', text: '対象外'};

// パイロットキャンセル理由
export const MATCHING_PILOT_CANCEL          = [
    {id:"0", value:"ドライブ不可（天候、車両不調等）"},
    {id:"1", value:"ドライバー都合によるキャンセル（体調不良等）"},
    {id:"2", value:"NO SHOW"} 
];

// 各ポジション
export const PLAYER_PILOT   = "pilot";
export const PLAYER_OWNER   = "owner";
export const PLAYER_BRROWER = "brrower";

//定数マスタID
// constants.phpからとる時
// define\('(.*)', (.*)\);(.*)
// export const $1 = $2; $3
export const CONSUMPTION_TAX = 1;            // 消費税
export const AIRSHARE_COMMISSION = 2;        // AirShare手数料（20%）
export const FLIGHT_ROUND_UP_MINUTES = 3;    // フライト時間切り上げ単位
export const TAKEOFF_RUNWAY_ROLL_TIME = 4;   // 離陸地上滑走時間(分)
export const TAKEOFF_TIME = 5;               // 離陸上昇時間
export const LANDING_TIME = 6;               // 着陸進入時間
export const LANDING_RUNWAY_ROLL_TIME = 7;   // 着陸後地上滑走時間
export const PAYJP_FEE_RATE = 8;             // PAY.JP売上手数料率(最大)
export const PAYJP_FEE_AMOUNT = 9;           // PAY.JP入金手数料
export const CANCEL_AIRCRAFT_FEE_RATE = 10;  // 当日キャンセルの機体レンタル費用
export const OWNERS_MEDICAL_INSURANCE_DAYS = 11;  // 搭乗者医療保険（最大日数）
export const OWNERS_MEDICAL_INSURANCE = 12;  // 搭乗者医療保険（日額）
export const OWNERS_DEATH_INSURANCE = 13;    // 搭乗者死亡保険
export const OWNERS_COMPENSATION = 14;       // 第三者賠償
export const PAYJP_FEE_RATE_FOR_PILOT = 15;  // B負担PAY.JP売上手数料率(最大)
export const AIRSHARE_COMMISSION_CO = 20;    // AirShare手数料率 [事業用機] - （5%）

// マッチング費用ステータス
// 　ボロワ明細　total_costとdiff_costはchange_infoベース（ボロワのキャンセル費用はtotal_cost）
// 　　パイロット雇用費用
export const COST_TOTAL = 1;                                // 費用合計
export const COST_PILOT_CHARGE = 2;                         // パイロットチャージ料
export const COST_PILOT_FEE = 3;                            // フライトフィー
export const COST_PILOT_FEE_MOVING = 4;                     // 往路回送費
export const COST_PILOT_FEE_RETURN = 5;                     // 復路回送費
export const COST_PILOT_FARE = 6;                           // 交通費
export const COST_PILOT_PAY_COMMISSION = 7;                 // 支払手数料
export const COST_PILOT_SUBTOTAL = 8;                       // パイロット雇用費合計
// 　　航空機レンタル費用
export const COST_AIRCRAFT_CHARGE = 9;                      // 機体チャージ料
export const COST_AIRCRAFT_FEE = 10;                         // フライトフィー
export const COST_AIRCRAFT_FEE_MOVING = 11;                 // 往路回送費
export const COST_AIRCRAFT_FEE_RETURN = 12;                 // 復路回送費
export const COST_AIRCRAFT_COMPENSATION = 13;               // エアシェア補償
export const COST_AIRCRAFT_SUBTOTAL = 14;                   // 航空機レンタル費用
// 　　その他費用
export const COST_OTHER_COST = 15;                          // その他費用
export const COST_TAX = 16;                                 // 消費税（x%）
// オーナー明細
export const COST_TOTAL_FOR_OWNER = 17;                     // 受取金額合計
export const COST_AIRCRAFT_CHARGE_FOR_OWNER = 18;           // 機体チャージ料
export const COST_AIRCRAFT_FEE_FOR_OWNER = 19;              // フライトフィー
export const COST_AIRCRAFT_FEE_MOVING_FOR_OWNER = 20;       // 往路回送費
export const COST_AIRCRAFT_FEE_RETURN_FOR_OWNER = 21;       // 復路回送費
                                                               // エアシェア補償は同額
export const COST_TAX_FOR_OWNER = 22;                       // 消費税
export const COST_AIRCRAFT_SUBTOTAL_FOR_OWNER = 23;         // 航空機レンタル費用
export const COST_AIRCRAFT_AIRSHARE_COMMISSION_FOR_OWNER = 24;    // AirShare手数料(税込)
// パイロット明細   各費用はボロワと同額（パイロットはAirShare手数料がかからない）
export const COST_PILOT_TOTAL_FOR_PILOT = 25;               // P受取金額合計(支払い手数料を含まず)
// キャンセル金額
export const COST_CANCEL = 26;                              // Bキャンセル費用
export const COST_CANCEL_FOR_OWNER = 27;                    // Oキャンセル受取額
export const COST_CANCEL_FOR_PILOT = 28;                    // Pキャンセル受取額
export const COST_COUPON = 29;                              // クーポン割引額
export const COST_AGENCY_COMMISSION = 30                    // 特約店手数料
export const COST_OTHER_PLUS_COMMISSION = 31                // その他費用（AirShare手数料込航空機運航費用）
//マッチングフライト編集
export const MATCHING_EDIT_ROUTE_ADD            = 1;
export const MATCHING_EDIT_ROUTE_DELETE         = 2;
export const MATCHING_EDIT_ROUTE_CHANGE         = 3;

// 非表示はダミーを置く
export const TAB_INDEX_FLIGHT      = 0;
export const TAB_INDEX_COST        = 1;
export const TAB_INDEX_DOCUMENT    = 2;
// export const TAB_INDEX_CHAT        = 3;
// export const TAB_INDEX_CHECKLIST   = 4;
export const TAB_INDEX_PASSENGER   = 3;

// 画面ラベル(暫定)
export const LABEL_FLIGHT_SELECT_CONFIRM                      = "次へ";

//ダッシュボード　フライトチケット表示色切り替え期間
export const DAYS_AS_COMING_FLIGHT              = 50;
export const TICKET_COMMENT_WAIT                = "利用者個人承認待ちです";
export const TICKET_COMMENT_DURING              = "マッチング依頼中です";
export const TICKET_COMMENT_COMING              = "フライト予定日が近づいています";
export const TICKET_COMMENT_VALID               = "マッチングが成立しました";

export const CONST_MYPAGE_POLLING_INTERVAL      = 10000;
export const NUMBER_OF_NEWS_ITEMS               = 5;

//constant.phpと合わせる
export const MESSAGE_ID_CHATMESSAGE             = 18;
export const MESSAGE_ID_PILOT_MATCHING          = 11;
export const MESSAGE_ID_PILOT_MATCHING_CANCELLED_BY_BORROWER    = 12;
export const MESSAGE_ID_PILOT_MATCHING_CHANGE_AUTHORIZED        = 13;
export const MESSAGE_ID_PILOT_MATCHING_CHANGE_REJECTED          = 14;
export const MESSAGE_ID_OWNER_MATCHING          = 21;
export const MESSAGE_ID_OWNER_MATCHING_CANCELLED_BY_BORROWER    = 22;
export const MESSAGE_ID_OWNER_MATCHING_CHANGED                  = 23;
export const MESSAGE_ID_BORROWER_PILOT_CONTRACTED               = 24;
export const MESSAGE_ID_BORROWER_OWNER_CONTRACTED               = 25;
export const MESSAGE_ID_BORROWER_MATCHING_CANCELLED             = 26;
export const MESSAGE_ID_BORROWER_MATCHING_CHANGED               = 27;

export const MESSAGE_ID_PILOT_LICENSE_FIXED_EXPIRING       = 29;
export const MESSAGE_ID_PILOT_LICENSE_ROTARY_EXPIRING      = 30;
export const MESSAGE_ID_PILOT_CERTIFICATE_EXPIRING         = 31;
export const MESSAGE_ID_OWNER_CERTIFICATE_EXPIRING         = 32;
export const MESSAGE_ID_PILOT_PAYMENT_DONE                 = 33;
export const MESSAGE_ID_OWNER_PAYMENT_DONE                 = 34;
export const MESSAGE_ID_BORROWER_PAYMENT_DONE              = 35;
export const MESSAGE_ID_BORROWER_COUPON_ISSUED             = 36;
export const MESSAGE_ID_PILOT_ENG_EXPIRING                 = 39;
export const MESSAGE_ID_PILOT_MNF_REQUIRE                  = 42;
export const MESSAGE_ID_PILOT_MNF_H_REQUIRE                = 43;
export const REMINED_PILOT_MNF                                  = 44;
export const REMINED_PILOT_MATCHING                             = 45;
export const REMINED_OWNER_MATCHING                             = 46;
export const REMINED_BORROWER_MATCHING                          = 47;
export const REMINED_PILOT_MATCHING_NO_AGREE                    = 48;
export const REMINED_OWNER_MATCHING_NO_AGREE                    = 49;
export const REMINED_BORROWER_MATCHING_NO_AGREE                 = 50;
export const PILOT_MATCHING_CANCEL_NO_MNF                       = 51;
export const OWNER_MATCHING_CANCEL_NO_MNF                       = 52;
export const BORROWER_MATCHING_CANCEL_NO_MNF                    = 53;
export const PILOT_MATCHING_CANCEL_NO_AGREE                     = 54;
export const OWNER_MATCHING_CANCEL_NO_AGREE                     = 55;
export const BORROWER_MATCHING_CANCEL_NO_AGREE                  = 56;
export const BORROWER_MATCHING_CREATE                           = 57;
export const REMINED_PILOT_FLIGHT_COMPLETE                      = 58;
export const REMINED_PILOT_SKILL_UPDATE                         = 59;
export const PILOT_MATCHING_CANCEL_PILOT_EXPIRED                = 60;
export const OWNER_MATCHING_CANCEL_PILOT_EXPIRED                = 61;
export const BORROWER_MATCHING_CANCEL_PILOT_EXPIRED             = 62;
export const PILOT_EXPIRED_MATCHING                             = 63;
export const AIRCRAFT_EXPIRED_MATCHING                          = 64;
export const MATCHING_CHANGE_AIRCRAFT_PILOT                     = 65;
export const MATCHING_CHANGE_AIRCRAFT_OWNER                     = 66;
export const REMINED_PILOT_REMAINING_TIME                       = 67;
export const REMINED_OWNER_REMAINING_TIME                       = 68;

export const NEWS_CHAT_MESSAGES                    = "新着のメッセージが%N%件あります。";
export const NEWS_TEXTS_MATCHING                   = "新しいマッチング依頼が%N%件あります。";

export const TYPE_MESSAGES_CLICK                    =1;

export const NEWS_MOVETO_MATCHING                   =1;
export const NEWS_MOVETO_MATCHING_DETAIL            =2;
export const NEWS_MOVETO_CHAT                       =3;
export const NEWS_MOVETO_PILOT_INFO                 =4;
export const NEWS_MOVETO_OWNER_INFO                 =5;
export const NEWS_MOVETO_USER_INFO                  =6;
export const NEWS_MOVETO_COUPON_INFO                =7;
export const NEWS_NO_MOVE                           =99;

//以下、constant.php と定義を合わせること
export const MESSAGE_DELETE_BY_MESSAGEID            =1;
export const MESSAGE_DELETE_BY_MATCHINGID           =2;
export const MESSAGE_DELETE_BY_MATCHINGID_CHAT      =3;
export const MESSAGE_DELETE_ALL_PO_MATCHING         =4;
export const MESSAGE_DELETE_BY_USERID               =5;
export const MESSAGE_DELETE_BY_SUBJECT              =6;
export const MESSAGE_DELETE_BY_COUPONCODE           =7;
export const MESSAGE_NO_DELETE                      =99;

// 今すぐ出発の場合の操作時間から、実際の出発時間（分）
export const MINUTES_TO_DEPARTURE                   =15;
