import React, { useState } from "react";

//CSS読み込み
import './css/SelectBox.css';

const SHOW_NOINPUT = "NOINPUT";
const SHOW_FOCUS = "FOCUS";
const SHOW_INPUT = "INPUT";


function getClass(className="selectboxDefault", disabled=false, behaivor, validation, value) {
    let setStyle = "";
    if (disabled) {
        return className + " selectboxCommon " + "selectboxColor_noinput";
    }
    if (validation) {
        switch (behaivor){
            case SHOW_FOCUS:
            case SHOW_INPUT:
            default:
                setStyle = " selectboxColor_input";
                break;
        }
    } else {
        switch (behaivor){
            case SHOW_FOCUS:
            default:
                setStyle = " selectboxColor_Error";
                break;
        }
    }
    return className + " selectboxCommon " + setStyle;
}

function TestSelectBox(props) {
    const [behaivor, setBehaivor] = useState(SHOW_NOINPUT);

    return (
        <select 
            id={props.id} 
            value={props.value} 
            className={getClass(props.className, props.disabled, behaivor, props.validation, props.value)}
            onFocus={()=>setBehaivor(SHOW_FOCUS)} 
            onChange={(e)=>props.callback(props.id, e.target.value)}
            onBlur={()=>setBehaivor(props.value != "" ? SHOW_INPUT : SHOW_NOINPUT)} 
            disabled={props.disabled || props.options.length == 0 ? "disabled" : ""}>
            {props.options.map((value, index) => {
                return(
                    <option key={props.id + "_" + value.value} value={value.value}>{value.label}</option>
                );
            })}
        </select>
    );
}

export default TestSelectBox;