//export const REQUEST_POST = 'REQUEST_POST'
//export const RECEIVE_POST = 'RECEIVE_POST'
//export const DOMAIN = 'http://153.126.207.19/api/'

//定数定義読み込み
import * as Const from '../Const.js';

//関数
// var callback = null;
// export function setConnectionCB(callback_work) {
//     callback = callback_work;
// }

//通信処理入口
export function dispatchPostConnections(url, params, callback) {
    //イベントをdispatch
    return (dispatch, getState) => {
        //通信処理本体をdispatch
        return dispatch(postConnection(url, params, callback))
    }
}

//通信処理入口
export function dispatchFileUploadConnections(url, params, callback) {
    //イベントをdispatch
    return (dispatch, getState) => {
        //通信処理本体をdispatch
        return dispatch(fileUploadConnection(url, params, callback))
    }
}

//郵便番号用通信処理入口
export function dispatchAddressConnections(url, callback) {
    //イベントをdispatch
    return (dispatch, getState) => {
        //通信処理本体をdispatch
        return dispatch(addressConnection(url, callback))
    }
}


//通信処理本体
function postConnection(url, params, callback) {
    return (dispatch) => {
        //リクエストパラメータ生成
        dispatch(requestParams(url, params));

        var userid = localStorage.getItem("user_id");
        if (!(userid === null || userid === undefined || userid === -1 || userid === "-1")) {
            var hashcode = localStorage.getItem("hashcode");
            if (!(hashcode === null || hashcode === undefined || hashcode === -1 || hashcode === "-1")) {
                params["user_id"]  = userid;
                params["hashcode"] = hashcode;
            }
        }

        // 管理画面用
        if (params.manage_auth) {
            params["user_id"] = localStorage.getItem("manage_user_id");
            params["manage_hashcode"] = localStorage.getItem("manage_hashcode");
        }

        //FetchAPIで通信を行う
        return fetch(Const.DOMAIN + url, {
                body                : JSON.stringify(params),
                cache               : 'no-cache',
                headers: {
                    'user-agent'    : 'Mozilla/4.0 MDN Example',
                    'accept'        : 'application/json',
                    'content-type'  : 'application/json'
                },
                method              : 'POST',
                mode                : 'cors',
                redirect            : 'follow',
                referrer            : 'no-referrer',
            })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    // ステータスコード200-299以外はエラー
                    throw new Error(response.status);
                }
            })
            .then((json) => {
                //通信結果をdispatch
                callback(url, json, Const.CONNECT_SUCCESS);
                return dispatch(receiveParams(json, Const.CONNECT_SUCCESS));
            })
            .catch((err) => {
                //console.log(err);
                //通信結果（エラー）をdispatch
                callback(url, {status: err}, Const.CONNECT_ERROR);
                return dispatch(receiveParams(err, Const.CONNECT_ERROR));
            }
        )
    }
}

//通信処理本体（ファイルアップロード）
function fileUploadConnection(url, params, callback) {
    return (dispatch) => {
        //リクエストパラメータ生成
        dispatch(requestParams(url, params));

        //FetchAPIで通信を行う
        return fetch(Const.DOMAIN + url, {
                method              : 'POST',
                body                : params,
                mode                : 'cors',
                headers: {
                    'Accept': 'application/json',
                },
            })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    // ステータスコード200-299以外はエラー
                    throw new Error(response.status);
                }
            })
            .then((json) => {
                //通信結果をdispatch
                //console.log("BBB :: " + json);
                callback(url, json, Const.CONNECT_SUCCESS);
                return dispatch(receiveParams(json, Const.CONNECT_SUCCESS));
            })
            .catch((err) => {
                //通信結果（エラー）をdispatch
                //console.log("Error :: " + err);
                callback(url, {status: err}, Const.CONNECT_ERROR);
                return dispatch(receiveParams("", Const.CONNECT_ERROR));
            }
        )
    }
}

//住所取得用の通信処理本体
function addressConnection(url, callback) {
    return (dispatch) => {
        //リクエストパラメータ生成
        dispatch(requestParams(url));

        //FetchAPIで通信を行う
        return fetch(url)
            .then((response) => {
                //console.log("Address Get 1");
                //console.log(response);
                return response.json()
            })
            .then((json) => {
                //通信結果をdispatch
                //console.log("Address Get 2");
                //console.log(json);
                callback(url, json, Const.CONNECT_SUCCESS);
                return dispatch(receiveParams(json, Const.CONNECT_SUCCESS));
            })
            .catch((err) => {
                //console.log(err);
                //通信結果（エラー）をdispatch
                callback(url, "", Const.CONNECT_ERROR);
                return dispatch(receiveParams("", Const.CONNECT_ERROR));
            }
        )
    }
}


//リクエストパラメータ
function requestParams(url, parameters) {
    return {
        type:Const.CONNECT_START,
        url:url,
        parameters:parameters
    };
}

//レスポンスパラメータ
function receiveParams(json, type) {
    //console.log("response");
    //console.log(json);
    if (!json.length) {
        return {
            type:type,
            parameters:[]
        };
    }

    return {
        type:type,
        parameters:json
    };
}

