//定数定義読み込み
import * as Const from '../Const.js';

//import {
//    REQUEST_POST,
//    RECEIVE_POST
//} from '../actions/PostActions'

//Reducer定義
//各アクション発生時のstate更新を行う
//state更新後、それぞれのViewで所持するstateが更新される
const PostReducer = (state = { url: '', parameters: [], status : 0 }, action) => {
    switch (action.type) {
        case Const.CONNECT_START:
            return Object.assign({}, state, {
                url         : action.url,
                parameters  : action.parameters,
                type        : action.type
            })
        case Const.CONNECT_SUCCESS:
            if (action.parameters.success === true) {
                return Object.assign({}, state, {
                    url         : action.url,
                    parameters  : action.parameters,
                    type        : action.type
                })
            }
            else {
                return Object.assign({}, state, {
                    url         : action.url,
                    parameters  : action.parameters,
                    type        : Const.CONNECT_ERROR
                })
            }
        case Const.CONNECT_ERROR:
            //console.log("action");
            //console.log(action);
            return Object.assign({}, state, {
                url         : action.url,
                parameters  : action.parameters,
                type        : action.type
            })
        default:
            return state
    }
}

export default PostReducer
