import React from 'react';
import { withRouter } from "react-router-dom"; //画面遷移に必要

//CSS読み込み
import './css/Landing.css';

//画像読み込み
import landing_shot0 from './images/landing_shot0.jpg';
import landing_shot1 from './images/landing_shot1.jpg';
import landing_shot2 from './images/landing_shot2.jpg';
import landing_createRoundtrip from './images/landing_roundtrip.svg';
import landing_createSightseeing from './images/landing_sightseeing.svg';
import lessThanWhite from './images/lessThanWhite.svg';
import greaterThanWhite from './images/greaterThanWhite.svg';
//定数定義読み込み
import * as Const from '../Const.js';

import TimerDialog from '../modules/TimerDialog.js';

class Landing extends React.Component {
    constructor(props) {
        super(props);

        //各コールバック関数をバインド

        this.state = {
            activeslide:0,
            autoslide:true,
            interval_restart_slide:null,
            wait_restart_slide:3
        };

        //関数をバインド
        this.goPage = this.goPage.bind(this);
        this.gotoSlide = this.gotoSlide.bind(this);
        this.gonextSlide = this.gonextSlide.bind(this);
        this.goprevSlide = this.goprevSlide.bind(this);

    }

    goPage(page) {
        this.props.history.push(page);
    }

    gotoSlide(no){
        if (no === this.state.activeslide) return;
        if (no === (this.state.activeslide===2 ? 0 : this.state.activeslide+1))
            this.gonextSlide();
        else 
            this.goprevSlide();
    }

    gonextSlide(){
        let slideIndex = this.state.activeslide;
        this.setState({activeslide:(slideIndex < 2 ? slideIndex+1 : 0)});
        this.setState({'autoslide' : true, 'wait_restart_slide' : 10});
    }

    goprevSlide(){
        let slideIndex = this.state.activeslide;
        this.setState({activeslide:(slideIndex > 0 ? slideIndex-1 : 2)});
        this.setState({'autoslide' : false, 'wait_restart_slide' : 10});
    }

    componentDidMount() {
        document.title = Const.DASHBOARD_TITLE_LANDING_PAGE;

        var interval_restart_slide = setInterval(() => {

                if(this.state.wait_restart_slide<0){
                        this.gonextSlide();
                        this.setState({'wait_restart_slide' : 3});
                }
                else{
                    this.setState({'wait_restart_slide' : this.state.wait_restart_slide-1});
                }
        },1000);

        this.setState({'interval_restart_slide' : interval_restart_slide});
    }

    componentWillUnmount() {
        clearInterval(this.state.interval_restart_slide);
    }

    //画面レイアウト
    render() {
        return (
            <div>
                <div className="landing" style={{overflowY:"auto", overflowX:"hidden", height:"70vh", minHeight:"500px"}}>
                    <div className="slideshowOuter">
                        <div className={this.state.autoslide ? "slideshow" : "slideshow_stop"}>
                            <div className="landingCtl">
                                <div className="landingMenu1">
                                    <div className="selectFlightType1">自由にドライブを作成しましょう</div>
                                    <div className="selectFlightType2">ご利用タイプを選択してください</div>
                                </div>
                                <div className="landingMenu">
                                    <div className="landingMenu23">
                                        <div className="landingMenuBox" onClick={()  => this.goPage("/CreateRoundNow")}>
                                            {/*<img className="imgSightseeing" alt="SightSeeing" src={landing_createSightseeing}/>*/}
                                            <div className="txtSightseeing">
                                                <div className="txtSightseeing1">今すぐ呼ぶ</div>
                                            </div>
                                        </div>
                                        <div className="landingMenuBox" onClick={()  => this.goPage("/CreateRound")}>
                                            {/*<img className="imgSightseeing" alt="RoundTrip" src={landing_createRoundtrip}/>*/}
                                            <div className="txtSightseeing">
                                                <div className="txtSightseeing1">配車予約</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="arw prev" id="goprev" onClick={(e)=>this.goprevSlide()}><span><img src={greaterThanWhite} alt="＜" /></span></div>
                                    <div className="arw next" id="gonext" onClick={(e)=>this.gonextSlide()}><span><img src={lessThanWhite} alt="＞" /></span></div>
                                    <div className="slidemove0">
                                        <div className="switches" id="switches">
                                            <input type="radio" name="slideshow" className="hideButton" id="switch0" onClick={(e)=>this.gotoSlide(0)}/>
                                            <label htmlFor="switch0" className={this.state.activeslide===0 ? "radiolabelactive" : "radiolabel"} id="radiolabel0">●</label>
                                            <input type="radio" name="slideshow" className="hideButton" id="switch1" onClick={(e)=>this.gotoSlide(1)}/>
                                            <label htmlFor="switch1" className={this.state.activeslide===1 ? "radiolabelactive" : "radiolabel"} id="radiolabel1">●</label>
                                            <input type="radio" name="slideshow" className="hideButton" id="switch2" onClick={(e)=>this.gotoSlide(2)}/>
                                            <label htmlFor="switch2" className={this.state.activeslide===2 ? "radiolabelactive" : "radiolabel"} id="radiolabel2">●</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <section style={{backgroundImage: `url(${landing_shot0})`}} className="slide" id={this.state.activeslide===0 ? "slide-0" :this.state.activeslide===1 ? "slide-0l" : "slide-0r"}>
                            </section>
                            <section style={{backgroundImage: `url(${landing_shot1})`}} className="slide" id={this.state.activeslide===1 ? "slide-1" :this.state.activeslide===2 ? "slide-1l" : "slide-1r"}>
                            </section>
                            <section style={{backgroundImage: `url(${landing_shot2})`}} className="slide" id={this.state.activeslide===2 ? "slide-2" :this.state.activeslide===0 ? "slide-2l" : "slide-2r"}>
                            </section>
                        </div>
                    </div>
                </div>
                
                <div className="landing_sp" style={{overflowY:"auto", overflowX:"hidden", height:"60vh", minHeight:"500px"}}>
                    <div className="slideshowOuter">
                        <div className={this.state.autoslide ? "slideshow" : "slideshow_stop"}>
                            <div className="landingCtl">
                                <div className="landingMenu1">
                                    <div className="selectFlightType1">自由に<br/>ドライブを作成しましょう</div>
                                    <div className="selectFlightType2">ご利用タイプを選択してください</div>
                                </div>
                                <div className="landingMenu">
                                    <div className="landingMenu23">
                                        <div className="landingMenuBox" onClick={() => this.goPage("/CreateRoundNow")}>
                                            <div className="txtSightseeing">
                                                <div className="txtSightseeing1">今すぐ呼ぶ</div>
                                            </div>
                                        </div>
                                        <div className="landingMenuBox" onClick={() => this.goPage("/CreateRound")}>
                                            <div className="txtSightseeing">
                                                <div className="txtSightseeing1">配車予約</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="arw prev" id="goprevSp" onClick={(e) => this.goprevSlide()}>
                                        <span><img src={greaterThanWhite} alt="＜"/></span></div>
                                    <div className="arw next" id="gonextSp" onClick={(e) => this.gonextSlide()}>
                                        <span><img src={lessThanWhite} alt="＞"/></span></div>
                                    <div className="slidemove0">
                                        <div className="switches" id="switchesSp">
                                            <input type="radio" name="slideshow" className="hideButton" id="switch0Sp"
                                                   onClick={(e) => this.gotoSlide(0)}/>
                                            <label htmlFor="switch0Sp"
                                                   className={this.state.activeslide === 0 ? "radiolabelactive" : "radiolabel"}
                                                   id="radiolabel0Sp">●</label>
                                            <input type="radio" name="slideshow" className="hideButton" id="switch1Sp"
                                                   onClick={(e) => this.gotoSlide(1)}/>
                                            <label htmlFor="switch1Sp"
                                                   className={this.state.activeslide === 1 ? "radiolabelactive" : "radiolabel"}
                                                   id="radiolabel1Sp">●</label>
                                            <input type="radio" name="slideshow" className="hideButton" id="switch2Sp"
                                                   onClick={(e) => this.gotoSlide(2)}/>
                                            <label htmlFor="switch2Sp"
                                                   className={this.state.activeslide === 2 ? "radiolabelactive" : "radiolabel"}
                                                   id="radiolabel2Sp">●</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <section style={{backgroundImage: `url(${landing_shot0})`}} className="slide"
                                     id={this.state.activeslide === 0 ? "slideSp-0" : this.state.activeslide === 1 ? "slideSp-0l" : "slideSp-0r"}>
                            </section>
                            <section style={{backgroundImage: `url(${landing_shot1})`}} className="slide"
                                     id={this.state.activeslide === 1 ? "slideSp-1" : this.state.activeslide === 2 ? "slideSp-1l" : "slideSp-1r"}>
                            </section>
                            <section style={{backgroundImage: `url(${landing_shot2})`}} className="slide"
                                     id={this.state.activeslide === 2 ? "slideSp-2" : this.state.activeslide === 0 ? "slideSp-2l" : "slideSp-2r"}>
                            </section>
                        </div>

                    </div>
                </div>

                <TimerDialog nullify={true}/>
            </div>
        );
    }

}

// function mapStateToProps(state) {
//     return {
//         url: state.url,
//         parameters: state.parameters,
//         type: state.type
//     };
// }
// export default connect(mapStateToProps)(Landing)
//export default connect()(withSize()(Landing))
export default withRouter(Landing)
