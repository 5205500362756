import React, { useState, useEffect } from "react";
import {useDispatch} from 'react-redux';
import { useHistory } from "react-router-dom";

//通信用のモジュールを読み込み
import { dispatchPostConnections } from '../actions/PostActions';

//CSS読み込み
import './css/FAQ.css';
import searchWhite from './images/searchWhite.svg';
import top_img16 from '../modules/images/top_16.svg';
import loader  from '../modules/images/loader.gif';

//定数定義読み込み
import * as Const from '../Const.js';
import * as Util from '../Util.js';

import Button  from '../modules/Button';
import {Dialog, ERROR_CODE, DIALOG_CODE} from '../modules/TestDialog.js';
import Modal  from '../modules/Modal';

const CONNECT_TYPE_SETUP   = "FAQ/setup";
const CONNECT_TYPE_CATEGORY = "FAQ/category";
const CONNECT_TYPE_PICKUP   = "FAQ/pickup";
const CONNECT_TYPE_PLAYER   = "FAQ/player";
const CONNECT_TYPE_SUB      = "FAQ/sub";
const CONNECT_TYPE_SEARCH      = "FAQ/search";

// ログイン前のコンポーネントで制御がよい？
export function FAQ(props) {
    const [dialogs, setDialogs] = useState({error:[]});
    const [loading, setLoading] = useState(false);
    let history = useHistory();

    useEffect(() => {
        document.title = 'よくある質問';
    }, []);

    // わかりづらい
    const dialogAdd = (name, content) => {
        if (dialogs[name] != null) {
            let setValue = {};
            setValue[name] = [...dialogs[name]];
            setValue[name].push(content);
            setDialogs({...dialogs, ...setValue});
        } else {
        }
    }

    return (
        <div style={{backgroundColor: "#fff", minHeight: "100%", paddingBottom: "30px"}}>     
            {loading ? 
                <Modal>
                    <div style={styles.loadBlackPanel}><img src={loader} style={{width:"100px", height:"100px"}}/></div>
                </Modal>
                : null}
            {dialogs.error.length > 0 ?
                <Dialog title={DIALOG_CODE.faq[dialogs.error[0].code].title} body={dialogs.error[0].code == ERROR_CODE ? dialogs.error[0].body : DIALOG_CODE.faq[dialogs.error[0].code].body}>
                    <Button id="errorDialog" value="戻る" classname="buttonshape buttondialog buttonblue" callback={() => {setDialogs({error: Util.arrayHeadRemove([...dialogs.error])});}}/>
                </Dialog>
            : null}
            <div style={{...styles.flexCenter, padding: "20px"}}>
                <div style={{width: "100%", flexShrink: 1}}></div>
                <div className="FAQmainTitle" style={{flexShrink: 0}}>よくあるご質問（FAQ)</div>
                <div style={{height: 60, width: "100%", flexShrink: 1, display: "flex", justifyContent: "flex-end"}}>
                    <img style={{height: "100%", cursor: "pointer"}} onClick={() => history.push("/Top")} src={top_img16} alt="top_16"/>
                </div> 
            </div>
            <div style={{margin: "0 30px"}}>
                <FAQContents setLoading={setLoading} dialogAdd={dialogAdd}/>
            </div>
        </div>
    );
}

export function FAQContents(props) {
    const dispatch = useDispatch();
    let history = useHistory();
    const defaultPlayer = {borrower: 1, pilot: 0, owner: 0};

    const PILOT = ["/top.html?for=pilot", "/top.html?for=terms_pilot"];
    const OWNER = ["/top.html?for=owner", "/top.html?for=terms_owner"];
    const getPlayer = () => {
        let player = {...defaultPlayer}
        if (history.state && history.state.from) {
            let path = history.state.from;
            switch (history.state.from) {
                default:
                    // TODO:ログイン前がReact化された場合
                    break;
            }
        } else if (document.referrer != "") {
            let thisPage = document.referrer.substr(document.referrer.lastIndexOf("/"));
            PILOT.map(data => {
                if (data === thisPage) {
                    player.pilot = 1;
                    return;
                }
            });
            OWNER.map(data => {
                if (data === thisPage) {
                    player.owner = 1;
                    return;
                }
            });
        }
        return player
    }
    const [player, setPlayer] = useState(() => getPlayer());
    
    const [category, setCategory] = useState({});
    const [mainCategory, setMainCategory] = useState([]);
    const [subCategory, setSubCategory] = useState({});
    const [pickUp, setPickUp] = useState([]);
    const [faqContent, setFAQContent] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [searchCategory, setSearchCategory] = useState({});
    const [categoryLength, setCategoryLength] = useState(0);
    // const [categoryMediaQuery, setCategoryMediaQuery] = useState(window.matchMedia("(max-width: 0px)"));
    const [displayDummy, setDisplayDummy] = useState(false);


    const textChange = (value) => {
        setSearchText(value);
    }

    const search = () => {
        let keyword = searchText.replace(/　/g, " ");
        keyword = keyword.split(" ").filter(text => text.length > 0);
        let main = searchCategory.main_category_id ? searchCategory.main_category_id :null;
        let sub = searchCategory.sub_category_id ? searchCategory.sub_category_id :null;

        keyword = keyword.length > 0 ? keyword : null;
        if (keyword || main || sub) {
            setPickUp([]);  // リセット
            handleConnect(CONNECT_TYPE_SEARCH, {keyword: keyword, main_category_id: main, sub_category_id: sub}, handleConnectChange);
        } else {
            setFAQContent([]);  // リセット
            handleConnect(CONNECT_TYPE_SETUP, {player :player}, handleConnectChange);
        }
    }

    const handleResizeDummy = () => {
        let box = document.getElementById("categoryBox");
        setDisplayDummy(box ? box.getBoundingClientRect().width < (15 * (categoryLength + 0.5) * mainCategory.length) : false); // なぜか初期表示だとcategoryBoxのサイズが大きく表示されるので0.5で予備
    }

    const handleConnect = (type, data, callback) => {
        props.setLoading(true);
        dispatch(dispatchPostConnections(type, data, callback));
    }

    const handleConnectChange = (type, data, status) => {
        props.setLoading(false);

        if (status !== Const.CONNECT_SUCCESS || !data.success) {
            props.dialogAdd("error", {code: ERROR_CODE, body:data.data && data.data.error_msgs ? data.data.error_msgs[0] : "サーバとの接続に失敗しました。"});
            return;
        }
            
        switch (type) {
            case CONNECT_TYPE_SETUP:
                // CONNECT_TYPE_PLAYER
                let player = data.data.player;
                setPlayer(player);
                // CONNECT_TYPE_CATEGORY
                let showSub = data.data.sub.filter(sub =>
                    (player.borrower == 1 && sub.borrower == 1) || (player.pilot == 1 && sub.pilot == 1) || (player.owner == 1 && sub.owner == 1));
                let setLength = 0;
                showSub.map(data => {if (data.category_name.length > setLength) setLength = data.category_name.length + 2});
                setCategoryLength(setLength);
                let sortSub = Util.sortDBtoKeyObject("main_category_id", showSub);
                setSubCategory(sortSub);
                let sortMain = data.data.main.filter(main => sortSub[main.main_category_id] != null);
                setMainCategory(sortMain);
                // キー値での取得（TODO:配列側をキーのみに？）
                let faq = {};
                let fullSub = Util.sortDBtoKeyObject("main_category_id", data.data.sub);
                data.data.main.map(main => {
                    faq[main.main_category_id] = main;
                    fullSub[main.main_category_id].map(sub => {
                        if (!faq[main.main_category_id].sub) faq[main.main_category_id].sub = {};
                        faq[main.main_category_id].sub[sub.sub_category_id] = sub;
                    });
                });
                setCategory(faq);
                
                // CONNECT_TYPE_PICKUP
                setPickUp(data.data.pickUp);
                break;
            case CONNECT_TYPE_SUB:
                setFAQContent(data.data);
                break;
            case CONNECT_TYPE_SEARCH:
                setFAQContent(data.data);
                break;
        }
    }

    const handleConnectSelectSub = (main, sub) => {
        handleConnect(CONNECT_TYPE_SUB, {player :player, main_category_id :main, sub_category_id: sub}, handleConnectChange);
    }

    useEffect(() => {
        handleConnect(CONNECT_TYPE_SETUP, {player :player}, handleConnectChange);
    }, []);

    useEffect(() => {
        handleResizeDummy();
        window.addEventListener('resize', handleResizeDummy);
        return () => window.removeEventListener('resize', handleResizeDummy);
    }, [mainCategory, categoryLength]);

    return (
        <React.Fragment>
            <div style={styles.title}>キーワード検索</div>
            <div style={{margin: "0 20px"}}>
                <KeywordSearch mainCategory={mainCategory} setMainCategory={setMainCategory} subCategory={subCategory} setSubCategory={setSubCategory} 
                                searchCategory={searchCategory} setSearchCategory={setSearchCategory} 
                                value={searchText} textChange={textChange} buttonClick={() => search()}/>
            </div>
            <div style={styles.title}>カテゴリから探す</div>
            <div id="categoryBox" className="categoryBox">
                {mainCategory.map((data, index) => {
                    if (subCategory[data.main_category_id]) {
                        return <CategoryBox key={"CategoryBox_" + data.main_category_id} {...data} sub={subCategory[data.main_category_id]} subClick={handleConnectSelectSub} max={categoryLength}/>
                    }
                })}
                {(() => {
                    if (displayDummy){
                        let dummys = [];
                        for (let i = mainCategory.length -1; i < mainCategory.length * 2; i++) {
                            dummys.push(<DummyCategoryBox max={categoryLength}/>);
                        }
                        return dummys;
                    }
                })()}
            </div>
            {pickUp.length > 0 && faqContent.length == 0 ? 
                <div>
                    <div style={styles.title}>人気のご質問</div>
                    {pickUp.map((data, index) => {
                        return <FAQBox key={"FAQBox_pickUp_" + index} {...data} />
                    })}
                </div>
            : null}
            {faqContent.length > 0 ?
                <React.Fragment>{
                    (() => {
                        let faq = [];
                        let main = faqContent[0].main_category_id;
                        let sub = faqContent[0].sub_category_id;
                        faq.push(<div key={main+'0'} style={styles.title}>{category[main].category_name}</div>);
                        faq.push(<div key={main+sub+'0'} style={styles.subTitle}>{category[main].sub[sub].category_name}</div>);
                        faqContent.map((data, index) => {
                            if (main != data.main_category_id) {
                                main = data.main_category_id;
                                faq.push(<div key={main+index} style={styles.title}>{category[main].category_name}</div>);
                                sub = data.sub_category_id;
                                faq.push(<div key={main+sub+index} style={styles.subTitle}>{category[main].sub[sub].category_name}</div>);
                            }
                            if (sub != data.sub_category_id) {
                                sub = data.sub_category_id;
                                faq.push(<div key={main+sub+index} style={styles.subTitle}>{category[main].sub[sub].category_name}</div>);
                            }
                            faq.push(<FAQBox key={"FAQBox_pickUp_" + index} {...data} />);
                        });
                        return faq;
                    })()
                }</React.Fragment>
            : null}
        </React.Fragment>
    );
}

function KeywordSearch(props) {
    const [showSelectCategory, setShowSelectCategory] = useState(false);

    return(
        <form method="POST" autoComplete="off" 
                 onSubmit={(e) => {
                    e.preventDefault();
                    document.getElementById("searchText").blur();
                    props.buttonClick();}}>
            <div style={{border: "2px solid #4788ee", display: "flex", height: "40px", borderRadius: "2px", paddingLeft:"0.5em", position:"relative"}}> 
                    <div style={{flexShrink: 1, width: "100%", height: "100%", position:"relative"}}>
                        <input id="searchText" style={{width: "100%", height: "100%", WebkitAppearance:"none"}} placeholder={"キーワードを選択／入力"} value={props.value} onClick={() => setShowSelectCategory(true)} onChange={(e) => props.textChange(e.target.value)} onBlur={() => setShowSelectCategory(false)} />
                    </div>
                    <div style={{flexShrink: 0, ...styles.flexCenter, background: "#4788ee", color: "#fff", width: "100px", cursor:"pointer"}} onClick={props.buttonClick}>
                        <img style={{width:"16px", height:"16px"}} src={searchWhite} alt="searchIcon"/>検索
                    </div>
                {showSelectCategory ?
                    <ul className="categorySearchList">{
                    props.mainCategory.map((main, index) => {
                        let selectCategory = [];
                        if (props.subCategory[main.main_category_id]){
                            selectCategory.push(<li key={"search_" + main.main_category_id} onMouseDown={() => {props.setSearchCategory(main); }} style={{paddingLeft:4, fontWeight:"bold"}}>{main.category_name}</li>);
                            props.subCategory[main.main_category_id].map((sub, index) => {
                                selectCategory.push(<li key={"search_" + sub.main_category_id + "_" + sub.sub_category_id} onMouseDown={() => props.setSearchCategory(sub)} style={{paddingLeft:20}}>{sub.category_name}</li>);
                            })
                        }
                        return selectCategory;
                    })
                    }</ul>
                : null}
            </div>
            <div style={{display: "flex", flexWrap: "wrap"}}>
                {props.searchCategory.sub_category_id ? 
                    <SelectedCategory onClick={() => props.setSearchCategory({})} {...props.searchCategory}/>
                    : props.searchCategory.main_category_id ? 
                    <SelectedCategory setStyle={{fontWeight:"bold"}} onClick={() => props.setSearchCategory({})} {...props.searchCategory}/>
                    : null}
            </div>
        </form>

    );
}

function SelectedCategory(props) {
    return (
        <div onClick={props.onClick} style={{...(props.setStyle?props.setStyle:null), cursor:"pointer", margin:"5px", padding: "5px", background:"#eee", borderRadius:2, flexShrink: 0, ...styles.flexCenter}}>{props.category_name}×</div>
    )
}

function CategoryBox(props) {
    const [toggle, setToggle] = useState(false);
    const [contentHeight, setContentHeight] = useState(0);
    const dummy = () => {
        let dummy = "";
        for (let i = 0; i < props.max; i++) {dummy += "　"}
        return dummy;
    }

    useEffect(() => {
        let box = document.getElementById(props.main_category_id + "_SubCategoryBox");
        setContentHeight(box.getBoundingClientRect().height);
    }, []);

    return(
        <div style={{...styles.flexColumn, minWidth: "200px"}}>
            <div style={{width: 40, height: 40, margin: "5px 0"}}><img src={Const.FAQ_CATEGORY_ICON_URL + props.img_filename} alt="LOGO" style={{height: "100%"}}/></div>
            <div onClick={() => setToggle(!toggle)} style={{...styles.flexColumn, fontWeight: "bold", cursor: "pointer"}}>
                <div style={{margin: "5px 0"}}>{props.category_name}</div>
                <div className={"subCategoryTriangle"} style={{transform: (toggle ? "rotate(0deg)" : "rotate(180deg)")}} />
            </div>
            <div className={"subCategoryBox"} style={{height: (toggle ? contentHeight : 0)}} >
                <div id={props.main_category_id + "_SubCategoryBox"}>
                    {props.sub.map(data => <SubCategory key={"SubCategory_" + props.main_category_id + "_" + data.sub_category_id} subClick={props.subClick} {...data} />)}
                </div>
            </div>
            <div style={{overflowY: "hidden"}}>{dummy()}</div>
        </div>
    )
}

function DummyCategoryBox(props) {
    const dummy = () => {
        let dummy = "";
        for (let i = 0; i < props.max; i++) {dummy += "　"}
        return dummy;
    }
    return (
        <div style={{height:"0", overflowY: "hidden"}}>{dummy()}</div>
    )
}

function SubCategory(props) {
    // marginが取得されないのでheightの空要素で余白
    return(
        <React.Fragment>
            <div style={{height: "5px"}}></div>
            <div style={{cursor: "pointer"}} onClick={() => props.subClick(props.main_category_id, props.sub_category_id)}><span style={{borderBottom: "1px solid #333"}}>{props.category_name}</span>＞</div>
            <div style={{height: "5px"}}></div>
        </React.Fragment>
    )
}
function createMarkup(html) {
    return {__html: html};
}

function FAQBox(props) {
    return(
        <div style={{borderBottom: "1px solid #ccc", marginBottom:"16px"}}>
            <div style={{display: "flex", alignItems: "baseline"}}>
                <div style={{...styles.flexCenter, width: "16px", height: "16px", borderRadius: "8px", fontSize: "12px", color: "#fff", flexShrink:0, background: "#f66"}}>Q</div>
                <div style={{width: "1em"}}/>
                <div style={{borderBottom: "1px solid #333", fontWeight:"bold", color: "#002060"}}>{props.question}</div>
            </div>
            <br />
            <div style={{display: "flex", alignItems: "baseline"}}>
                <div style={{...styles.flexCenter, width: "16px", height: "16px", borderRadius: "8px", fontSize: "12px", color: "#fff", flexShrink:0, background: "#3cf"}}>A</div>
                <div style={{width: "1em"}}/>
                <div style={{}}><div dangerouslySetInnerHTML={createMarkup(props.answer)} /></div>
            </div>
            <br />
        </div>
    )
}

const flexCenter = {display: "flex", justifyContent: "center", alignItems: "center"}; 
const styles = {
    title : {fontSize: 20, fontWeight: "bold", borderBottom: "3px solid #87ceeb", margin: "16px 0"},
    subTitle : {fontSize: 18, fontWeight: "bold", margin: "16px 0"},
    flexCenter : {...flexCenter}, 
    flexColumn : {display: "flex", flexDirection: "column", alignItems: "center"}, 
    loadBlackPanel : {...flexCenter, width : "100%",height : "100%",opacity : "0.5",background : "#aaa",position:"fixed"},
}